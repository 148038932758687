import React from 'react'
import styled from 'styled-components';
import {Card} from 'react-bootstrap'
import CardTitle from '../../../../common/styles/CardTitle'
import useBookStatisticsCategory from '../hooks/useBookStatisticsCategory';
import StatisticsTable from "../../../../common/StatisticsTable";
import StatisticsTableRow from "../../../../common/StatisticsTableRow";

import religion from '../../../../images/book-classifications/categories/religion.png'
import art from '../../../../images/book-classifications/categories/art.png'
import language from '../../../../images/book-classifications/categories/language.png'
import philosophy from '../../../../images/book-classifications/categories/philosophy.png'
import math from '../../../../images/book-classifications/categories/math.png'
import poetry from '../../../../images/book-classifications/categories/poetry.png'
import science from '../../../../images/book-classifications/categories/science.png'
import socialScience from '../../../../images/book-classifications/categories/social-science.png'
import history from '../../../../images/book-classifications/categories/history.png'
import other from '../../../../images/book-classifications/categories/other.png'
import question from '../../../../images/book-classifications/categories/question.png'

const BookStatisticsCategoryCard = () => {
    const [categories, isLoading] = useBookStatisticsCategory()

    const rows: StatisticsTableRow[] = [
        {
            id: 0,
            icon: other,
            name: '기타',
            value: categories?.othersCount?.toString() ?? '0',
        },
        {
            id: 0,
            icon: philosophy,
            name: '철학',
            value: categories?.philosophyCount?.toString() ?? '0',
        },
        {
            id: 0,
            icon: religion,
            name: '종교',
            value: categories?.religionCount?.toString() ?? '0',
        },
        {
            id: 0,
            icon: socialScience,
            name: '사회과학',
            value: categories?.socialScienceCount?.toString() ?? '0',
        },
        {
            id: 0,
            icon: science,
            name: '자연과학',
            value: categories?.naturalScienceCount?.toString() ?? '0',
        },
        {
            id: 0,
            icon: math,
            name: '기술',
            value: categories?.technologyCount?.toString() ?? '0',
        },
        {
            id: 0,
            icon: art,
            name: '예술',
            value: categories?.artCount?.toString() ?? '0',
        },
        {
            id: 0,
            icon: language,
            name: '언어',
            value: categories?.languageCount?.toString() ?? '0',
        },
        {
            id: 0,
            icon: poetry,
            name: '문학',
            value: categories?.literatureCount?.toString() ?? '0',
        },
        {
            id: 0,
            icon: history,
            name: '역사',
            value: categories?.historyCount?.toString() ?? '0',
        },
        {
            id: 0,
            icon: question,
            name: '?',
            value: categories?.unknownCount?.toString() ?? '0',
        },
    ]

    return (
        <Container>
            <ContainerBody>
                <CardTitle icon={<></>} title={`장르별 독서 요약`} url={''}/>

                <div className={'h-100'}>
                    <div className={'d-flex align-items-center'} style={{marginBottom: '10px'}}>
                        <StatisticsTable isLoading={isLoading} rows={rows}/>
                    </div>
                </div>
            </ContainerBody>
        </Container>
    )
}

const Container = styled(Card)`
    height: 100%;
    min-height: 650px;
`

const ContainerBody = styled(Card.Body)`
    height: 100%;
`

export default BookStatisticsCategoryCard
