import React from 'react'
import {Form} from "react-bootstrap";
import styled from "styled-components";

interface Props {
    year: number
    month: number
    day: number

    setYear: (year: number) => void
    setMonth: (month: number) => void
    setDay: (day: number) => void
}

const DateForm: React.FC<Props> = ({year, month, day, setYear, setMonth, setDay}) => {
    const getDaysInMonth = (year: number, month: number): number => new Date(year, month, 0).getDate()

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    return (
        <Row>
            <Col4>
                <Form.Select onChange={(e) => setYear(Number(e.target.value))} value={year}>
                    {
                        Array.from({length: 10}, (_, i) => {
                            const year = new Date().getFullYear() - i
                            return <option key={year} value={year}>{year}년</option>
                        })
                    }
                </Form.Select>
            </Col4>

            <Col4>
                <Form.Select onChange={(e) => setMonth(Number(e.target.value))} value={month}>
                    {
                        Array.from({length: year == currentYear ? currentMonth : 12}, (_, i) => {
                            const month = i + 1
                            return <option key={month} value={month}>{month}월</option>
                        })
                    }
                </Form.Select>
            </Col4>

            <Col4>
                <Form.Select onChange={(e) => setDay(Number(e.target.value))} value={day}>
                    {
                        Array.from({length: year == currentYear && month == currentMonth ? currentDay : getDaysInMonth(year, month)}, (_, i) => {
                            const day = i + 1
                            return <option key={day} value={day}>{day}일</option>
                        })
                    }
                </Form.Select>
            </Col4>
        </Row>
    )
}

const Row = styled.div.attrs({
    className: 'row'
})``

const Col2 = styled.div.attrs({
    className: 'col-12 col-md-2'
})``

const Col4 = styled.div.attrs({
    className: 'col-12 col-md-4'
})``

const Col5 = styled.div.attrs({
    className: 'col-12 col-md-5'
})``

const Col6 = styled.div.attrs({
    className: 'col-12 col-md-6'
})``

const Col10 = styled.div.attrs({
    className: 'col-12 col-md-10'
})``

export default DateForm
