import React from 'react'
import styled from "styled-components";
import {Spinner} from 'react-bootstrap'
import RowSpacer from "../../../../../common/styles/RowSpacer";

interface Props {
    message?: string
}

const BookSourceSectionLoading: React.FC<Props> = ({message}) => {
    return (
        <Container>
            <RowSpacer size={10}/>
            <Spinner animation="border" variant="book" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>

            {
                message &&
                <MessageContainer>
                    {message}
                </MessageContainer>
            }
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    text-align: center;
`

const MessageContainer = styled.h5`
    font-weight: bold;
`

export default BookSourceSectionLoading