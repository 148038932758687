import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { BooksitoutServer } from '../../config/BooksitoutServer'
import ApiUrls from '../../ApiUrls'
import Loading from '../../common/Loading'
import useUrlQuery from '../../common/hooks/useUrlQuery'
import LoginSuccessResponse from './LoginSuccessResponse'
import useLoginStore from './useLoginStore'

const OAuthRedirect = () => {
	const { provider } = useParams()

	const navigate = useNavigate()

	const code = useUrlQuery('code')
	const state = useUrlQuery('state')
	const scope = useUrlQuery('scope')

    const getAdditional = (provider: string | undefined) => {
        switch (provider?.toUpperCase()) {
            case 'NAVER':
                return state
            case 'GOOGLE':
                return scope
			case 'APPLE':
				return null
            default:
                return null
        }
    }

	const login = useLoginStore(state => state.login)

	React.useEffect(() => {
		const additional = getAdditional(provider)

		if (code !== null) {
			BooksitoutServer
				.get(ApiUrls.User.Login.POST(provider?.toUpperCase() as 'NAVER' | 'GOOGLE' | 'KAKAO' | 'APPLE', code, additional))
				.then((res) => res.data)
				.then((userData: LoginSuccessResponse) => {
					login(userData)
					toast.dismiss()
					navigate('/')

					if (userData.name === "") {
						toast(`어서오세요, 다시 만나서 반가워요!`, { icon: '✋' })
					} else {
						toast(`어서오세요, ${userData.name}님!`, { icon: '✋' })
					}
				})
				.catch((e) => {
					toast.error(e.message)
					navigate('/')
				})
		}
	}, [code, provider])

    return <Loading size={100} message={`로그인 하고 있어요`} />
}

export default OAuthRedirect
