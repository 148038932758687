import React, {useEffect} from 'react'
import {Toaster} from 'react-hot-toast'
import {Outlet, useLocation} from 'react-router-dom'

import Footer from './Footer'
import Topnav from './topnav/Topnav'
import ToastSettings from '../config/ToastSettings';
import AddFloatingButton from './button/AddFloatingButton';
import TimerFloatingButton from './button/TimerFloatingButton';
import LocationSettings from '../config/LocationSettings';
import RowSpacer from './styles/RowSpacer';
import BookReadingSessionModal from '../routes/book/reading/BookReadingSessionModal'
import ReadingSessionSettings from '../routes/book/reading/ReadingSessionSettings'
import BookReadingSessionEndModal from "../routes/book/reading/BookReadingSessionEndModal";
import Confetti from 'react-confetti'
import useReadingSessionStore from '../routes/book/reading/useReadingSessionStore'

const Root = () => {
    const footerIncludeUrl = [
        '/', '/introductions', '/values', '/faq', 'products', '/products', '/products/*',
        '/privacy/2023-07-01', '/privacy/2023-08-03',
        '/community/*', '/community'
    ]
    const footerIncludePatterns = footerIncludeUrl.map(pattern => {
        const regexPattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/\\\*/g, '.*');
        return new RegExp(`^${regexPattern}$`);
    })
    const isUrlIncluded = (url: string, patterns: RegExp[]) => {
        return patterns.some(pattern => pattern.test(url));
    }

    const location = useLocation()
    const [currentUrl, setCurrentUrl] = React.useState('')
    useEffect(() => {
        setCurrentUrl(location.pathname)
    }, [location])

    const {width, height} = useWindowSize()
    const {isPlayConfetti} = useReadingSessionStore()

    return (
        <>
            {
                isPlayConfetti &&
                <Confetti width={width} height={height}/>
            }

            <BookReadingSessionModal/>
            <BookReadingSessionEndModal/>

            <Toaster/>
            <ToastSettings/>
            <LocationSettings/>
            <ReadingSessionSettings/>

            <Topnav/>

            <Outlet/>

            <TimerFloatingButton/>
            <AddFloatingButton/>

            {isUrlIncluded(currentUrl, footerIncludePatterns) && <Footer/>}
            <RowSpacer/>
        </>
    )
}

function useWindowSize() {
    const [size, setSize] = React.useState({width: window.innerWidth, height: window.innerHeight});

    React.useEffect(() => {
        const handleResize = () => {
            setSize({width: window.innerWidth, height: window.innerHeight});
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return size;
}

export default Root
