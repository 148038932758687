class ColorConfig {
    static Primary = `#1CB15AFF`
    static PrimaryDark = `#0a3622`
    static Danger = `#FF4D4F`
    static DangerHover = `#FF4D4F`
    static Secondary = `#6C757D`

    static Pause = `#8B0000`
    static Resume = `#228B22`
}

export default ColorConfig;
