import {useEffect, useState} from 'react'
import LibraryResponse from './near/LibraryNearResponse'
import {BooksitoutServer} from '../../config/BooksitoutServer'
import ApiUrls from '../../ApiUrls'
import Paging from "../../common/hooks/Paging";

const useLibraryByBookCount = (size: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)

    const [libraries, setLibraries] = useState<LibraryResponse[]>([])

    const [page, setPage] = useState<number>(1)
    const [isLast, setIsLast] = useState<boolean>(false)
    const [totalPages, setTotalPages] = useState<number>(0)

    useEffect(() => {
        // TODO
        if (page <= 2) {
            BooksitoutServer
                .get(`/v1/library?sort=book-count&page=${page}&size=${size}`)
                .then(res => {
                    setLibraries([...libraries, ...(res.data.contents ?? [])])
                    setIsLast(res.data.isLast)
                    setTotalPages(res.data.totalPages)
                })
                .catch(() => setIsError(true))
                .finally(() => setIsLoading(false))
        } else {
            setIsLast(true)
        }
    }, [page])

    const paging: Paging = {
        hasMore: !isLast,
        totalPages: totalPages,
        fetchNext: () => setPage(page + 1)
    }

    return [libraries, isLoading, isError, paging] as const
}

export default useLibraryByBookCount
