import { useEffect, useState } from "react"
import { SearchBookSourceUsedOfflineResponse } from "../SearchBookSourceUsedResponse"
import { BooksitoutServer } from "../../../../config/BooksitoutServer"
import {BookSourceUsedOfflineValues} from "../BookSource";

const useBookUsedOffline = (isbn13: string) => {
    const [books, setBooks] = useState<SearchBookSourceUsedOfflineResponse[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (isbn13 !== '')  {
            BooksitoutServer
                .get(`/v1/book/search/sources/used/offline?isbn13=${isbn13}&sources=${Array.of(BookSourceUsedOfflineValues).join(",")}`)
                .then((res) => setBooks(res.data))
                .finally(() => setIsLoading(false))
        }
    }, [isbn13])

    return [books, isLoading] as const
}

export default useBookUsedOffline
