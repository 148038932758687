import {useState, useEffect} from 'react'

const useSearchQuery = (defaultValue: string, timeout: number) => {
    const urlQuery = new URLSearchParams(window.location.search).get('q')

    const [query, setQuery] = useState<string>(urlQuery ?? defaultValue)
    const [dQuery, setDQuery] = useState<string>(query)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDQuery(query)
        }, timeout)

        return () => {
            clearTimeout(handler)
        }
    }, [query])

    return {query, setQuery, dQuery}
}

export default useSearchQuery
