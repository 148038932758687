import {useEffect, useState} from "react"
import BookStatisticsCategoryResponse from "../responses/BookStatisticsCategoryResponse";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";

const useBookStatisticsCategory = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [categories, setCategories] = useState<BookStatisticsCategoryResponse | null>(null)

    useEffect(() => {
        BooksitoutServer
            .get(`/v1/statistics/categories`)
            .then((res) => setCategories(res.data))
            .finally(() => setIsLoading(false))
    }, [])

    return [categories, isLoading] as const
}

export default useBookStatisticsCategory