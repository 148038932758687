import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import BookSourceSection from "./common/BookSourceSection";
import useCurrentLocation from "../../../library/useCurrentLocation";
import useBookLibraryOffline from "../hooks/useBookLibraryOffline";
import {SearchBookSourceLibraryOfflineResponse} from "../SearchBookSourceLibraryResponse";
import ContentContainer from "../../../../common/styles/ContentContainer";
import RowSpacer from "../../../../common/styles/RowSpacer";
import LoanPossibleBadge from "./common/LoanPossibleBadge";
import ColSpacer from "../../../../common/styles/ColSpacer";
import BookSourceNoContent from "../BookSourceNoContent";
import BookSourceSectionLoading from "./common/BookSourceSectionLoading";
import breakpoints from "../../../../config/Breakpoints";
import useSearchSettings from "../hooks/useBookSearchSettings";
import BooksitoutIcon from '../../../../config/BooksitoutIcon';
import LoadingBar from "../../../../common/LoadingBar";

interface Props {
    isbn: string
}

const BookSourceOfflineLibrarySection: React.FC<Props> = ({isbn}) => {
    const [lat, long, locationName, isLocationLoading] = useCurrentLocation()
    const [librariesOffline, isLibraryOfflineLoading] = useBookLibraryOffline(isbn, lat, long)
    const [settings] = useSearchSettings()

    const [subTitle, setSubTitle] = useState<string>()
    useEffect(() => {
        if (settings?.librarySearchMethod == 'SPECIFIC_REGIONS') {
            setSubTitle(settings.regionDetails.map((region) => region.depth2.koreanName).join(', '))
        } else if (settings?.librarySearchMethod == 'SPECIFIC_LIBRARIES') {
            setSubTitle(settings.libraries.map((library) => library.name.replace(/\s*\([^)]*\)/g, "")).join(', '))
        } else {
            setSubTitle(locationName ?? '?')
        }
    }, [settings, isLocationLoading]);

    return (
        <BookSourceSection
            title={`도서관`}

            // @ts-ignore
            subTitle={
                (settings?.librarySearchMethod == 'CURRENT_LOCATION' || settings == null) && isLocationLoading ?
                    <LoadingBar size={5}/>
                    :
                    subTitle
            }

            right={
                <a href={'/settings/search'}>
                    <h3 className={'text-book'}>
                        <BooksitoutIcon.settings/>
                    </h3>
                </a>
            }

            // @ts-ignore
            body={
                isLocationLoading ?
                    <BookSourceSectionLoading message={'위치 정보를 가져오고 있어요'}/>
                    :
                    isLibraryOfflineLoading ?
                        <BookSourceSectionLoading/>
                        :
                        <Body librariesOffline={librariesOffline}/>
            }
        />
    )
}

interface BodyProps {
    librariesOffline: SearchBookSourceLibraryOfflineResponse[]
}

const Body: React.FC<BodyProps> = ({librariesOffline}) => {
    if (librariesOffline.length == 0) {
        return <BookSourceNoContent message={'도서관에는 책이 없어요'}/>
    }

    return (
        <>
            {
                librariesOffline.map(library => {
                    return (
                        <div className={'hover-effect w-100'}>
                            <a href={library.link!!} target={'_blank'} className={'w-100'}>
                                <RowSpacer size={10}/>
                                <Container>
                                    <div>
                                        <span className={'d-flex'}>
                                            {
                                                library.library.logo &&
                                                <>
                                                    <Icon src={library.library.logo}/>
                                                    <ColSpacer size={5}/>
                                                </>
                                            }
                                            <h6 className={'p-0 m-0 pt-1'}>{library.library.name}</h6>
                                        </span>

                                        <RowSpacer size={5}/>

                                        <span className={'d-flex'}>
                                            <LoanPossibleBadge isLoanPossible={library.loanPossible}/>
                                            {
                                                false &&
                                                <>
                                                    <ColSpacer size={10}/>
                                                    <LoanPossibleBadge isLoanPossible={library.reservationPossible}
                                                                       possibleMessage={'예약가능'}
                                                                       impossibleMessage={'예약불가'}/>
                                                </>
                                            }
                                        </span>
                                    </div>

                                    <BookLocationContainer>
                                        <BookLocationText>{library.bookLocation}</BookLocationText>
                                    </BookLocationContainer>
                                </Container>
                            </a>
                        </div>
                    )
                })
            }
        </>
    )
}

const Icon = styled.img`
    width: 30px;
    height: 30px;
`

const Container = styled(ContentContainer)`
    justify-content: space-between;
`

const BookLocationContainer = styled.div.attrs({
    className: 'force-1-line'
})`
    height: 100%;
    min-width: 125px;
    text-align: right;

    display: grid;
    place-items: flex-end;
`

const BookLocationText = styled.h6.attrs({
    className: 'text-secondary'
})`
    padding: 0;
    margin: 0;

    font-size: 0.9rem;
    @media (max-width: ${breakpoints.md}) {
        font-size: 0.8rem;
    }

`

export default BookSourceOfflineLibrarySection
