import React from 'react'
import styled from "styled-components";
import BookResponse from "./BookResponse";
import {Button as BootstrapButton} from 'react-bootstrap'
import {BooksitoutServer} from '../../config/BooksitoutServer';
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

interface Props {
    book: BookResponse
}

const BookGiveUpButton: React.FC<Props> = ({book}) => {
    const navigate = useNavigate()

    const onClick = (e) => {
        e.preventDefault()

        const confirmationMessage = book.isGiveUp ? '책을 다시 읽을까요?' : '책을 포기할까요?'

        if (!window.confirm(confirmationMessage)) {
            return
        }

        if (book.isGiveUp) {
            BooksitoutServer
                .put(`/v1/book/${book.id}/un-give-up`)
                .then(() => {
                    toast.success('책을 다시 읽을게요.')
                    navigate(`/book/mine?range=READING`)
                })
                .catch(() => toast.error(`오류가 났어요. 잠시 후 다시 시도해 주세요.`))
        } else {
            BooksitoutServer
                .put(`/v1/book/${book.id}/give-up`)
                .then(() => {
                    toast.success('책을 포기했어요.')
                    navigate(`/book/mine?range=GIVEUP`)
                })
                .catch(() => toast.error(`오류가 났어요. 잠시 후 다시 시도해 주세요.`))
        }
    }

    return (
        // @ts-ignore
        <Button onClick={onClick} isGiveUp={book.isGiveUp}>
            {book.isGiveUp ? '다시 읽기' : '포기하기'}
        </Button>
    )
}

const Button = styled(BootstrapButton).attrs({
    variant: 'book-danger'
})`
    margin-top: 10px;
    width: 100%;

    //max-width: ${props => props.isGiveUp ? '300px' : '100px'};
`;


export default BookGiveUpButton
