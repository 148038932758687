import styled from "styled-components";
import Login from "../../common/Login"
import RouteContainer from "../../common/styles/RouteContainer"
import useLoginStore from "./useLoginStore";
import {useEffect} from "react";
import toast from "react-hot-toast";

const LoginRoute = () => {
    const {isLoggedIn} = useLoginStore()

    useEffect(() => {
        if (isLoggedIn()) {
            toast.success(`이미 로그인 돼 있어요`)
            window.location.href = "/"
        }
    }, [isLoggedIn])

    return (
        <StyledRouteContainer>
            <Container>
                <Login message={null} />
            </Container>
        </StyledRouteContainer>
    )
}

const StyledRouteContainer = styled(RouteContainer)`
    @media (max-width: 767px) {
        height: 100vh;
    }
    
    @media (min-width: 768px) {
        height: 85vh;
        overflow-y: hidden;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export default LoginRoute