import React, {useEffect} from 'react'
import styled from 'styled-components';
import {useParams} from 'react-router-dom'
import getPrivacyData from '../../data/PrivacyData'
import parser from 'html-react-parser'
import NoContent from '../../common/NoContent';
import RouteContainer from '../../common/styles/RouteContainer'
import {Card} from 'react-bootstrap';
import RowSpacer from '../../common/styles/RowSpacer';
import CardBodyContentContainer from "../../common/styles/CardBodyContentContainer";
import useLanguage from "../../common/language/useLanguage";

const PrivacyByDate = () => {
    const { date } = useParams()
    const language = useLanguage()

    const [content, setContent] = React.useState<string>('')

    useEffect(() => {
        const english = `Privacy | Booksitout`
        const korean = `개인정보처리방침 | 책잇아웃`
        const japanese = `個人情報取扱方針 | booksitout`

        switch (language) {
            case "KOREAN":
                document.title = korean
                break
            case "JAPANESE":
                document.title = japanese
                break
            default:
                document.title = english
                break
        }
    }, [language]);

    React.useEffect(() => {
        if (date == null) return

        setContent(getPrivacyData(date, language) ?? '')
    }, [date, language])

    if (content === '') return <NoContent message='해당 날짜의 약관이 없어요'/>

    return (
		<RouteContainer>
            <RowSpacer />
            <RouteTitle>
                {
                    language == 'KOREAN' ? `${date?.split("-")[0]}년 ${date?.split("-")[1]}월 ${date?.split("-")[1]}일부터 시행되는 개인정보처리방침` :
                        language == 'ENGLISH' ? `Privacy Policy for ${date?.split("-")[1]}-${date?.split("-")[1]}, ${date?.split("-")[0]}` :
                            language == 'JAPANESE' ? `${date?.split("-")[0]}年${date?.split("-")[1]}月${date?.split("-")[1]}日から行われる施行される個人情報取扱方針` :
                                ``
                }
            </RouteTitle>

            <RowSpacer />
            <Card>
                <CardBodyContentContainer>
                    {parser(content)}
                </CardBodyContentContainer>
            </Card>

            <RowSpacer />
		</RouteContainer>
	)
}

const RouteTitle = styled.h1`
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
`;

export default PrivacyByDate