import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {Form} from 'react-bootstrap'

interface Props extends React.ComponentPropsWithoutRef<typeof Form.Control> {
    isFocusOnAppear?: boolean
    min?: number
    max?: number
}

const NumberInput: React.FC<Props> = ({isFocusOnAppear, min, max, ...rest}) => {
    const htmlInputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (htmlInputRef.current && isFocusOnAppear == true) {
            htmlInputRef.current.focus()
        }
    }, [isFocusOnAppear])

    const handleInput = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.valueAsNumber;
        let minCalculated = min ?? 0
        let maxCalculated = max ?? 10_000

        if (value.toString().startsWith('0')) {
            event.target.value = '0'
        }

        if (value < minCalculated) {
            event.target.value = minCalculated.toString()
        } else if (value > maxCalculated) {
            event.target.value = maxCalculated.toString()
        }
    }

    return (
        <Form.Control
            ref={htmlInputRef}
            type={'number'}
            pattern={'[0-9]*'}
            inputMode={'numeric'}
            onInput={handleInput}
            {...rest}
        />
    )
}

export default NumberInput
