import {useEffect} from "react";

const useHtmlTitle = (title: string | null | undefined) => {
    useEffect(() => {
        if (title == null || title == 'undefined' || title == '') {
            document.title = `책잇아웃`
        } else {
            document.title = `${title} | 책잇아웃`
        }
    }, [title])
}

export default useHtmlTitle