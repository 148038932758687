import toast from "react-hot-toast";
import {create} from "zustand";

interface ReadingSessionState {
    timerInSeconds: number
    isTimerOn: boolean
    bookId: number | null
    readingSessionId: number | null
    lastRecordedTime: string | null
    isShowingTimer: () => boolean

    start: (bookId: number, readingSessionId: number) => void
    stop: () => void
    incrementTimer: () => void
    resumeTimer: () => void
    pauseTimer: () => void

    isTimerModalOpen: boolean
    openTimerModal: () => void
    closeTimerModal: () => void

    isEndModalOpen: boolean
    openEndModal: () => void
    closeEndModal: () => void

    isPlayConfetti: boolean
    playConfetti: () => void
}

const useReadingSessionStore = create<ReadingSessionState>((set, get) => ({
    timerInSeconds: parseInt(localStorage.getItem('timerInSeconds') || '0', 10),
    isTimerOn: localStorage.getItem('isTimerOn') === 'true',
    bookId: localStorage.getItem('bookId') ? parseInt(localStorage.getItem('bookId')!, 10) : null,
    readingSessionId: localStorage.getItem('readingSessionId') ? parseInt(localStorage.getItem('readingSessionId')!, 10) : null,
    lastRecordedTime: localStorage.getItem('lastRecordedTime'),
    isShowingTimer: () => get().bookId != null && get().readingSessionId != null,

    start: (bookId: number, readingSessionId: number) => {
        set({isTimerModalOpen: true})

        let existingBookId = get().bookId
        if (bookId != null && existingBookId != null && existingBookId != bookId) {
            toast.error('이미 진행중인 독서활동이 있어요.')
            return
        }

        set({bookId: bookId});
        localStorage.setItem('bookId', bookId.toString())

        set({readingSessionId: readingSessionId})
        localStorage.setItem('readingSessionId', readingSessionId.toString())

        set({isTimerOn: true})
        localStorage.setItem('isTimerOn', 'true');

        const now = new Date()
        set({lastRecordedTime: now.toISOString()})
        localStorage.setItem('lastRecordedTime', now.toISOString());

        set({timerInSeconds: 1})
        localStorage.setItem('timerInSeconds', '1')
    },
    stop: () => {
        localStorage.removeItem('timerInSeconds')
        set({timerInSeconds: 0})

        localStorage.removeItem('isTimerOn')
        set({isTimerOn: false})

        localStorage.removeItem('bookId')
        set({bookId: null})

        localStorage.removeItem('readingSessionId')
        set({readingSessionId: null})

        localStorage.removeItem('lastRecordedTime')
        set({lastRecordedTime: null})

        set({isTimerModalOpen: false})
        set({isEndModalOpen: false})
    },

    incrementTimer: () => {
        if (!get().isTimerOn) return

        const now = new Date()
        const lastRecordedTime = get().lastRecordedTime ? new Date(get().lastRecordedTime!) : now
        const differenceInSeconds = Math.floor((now.getTime() - lastRecordedTime.getTime()) / 1000)
        const differenceToAdd = differenceInSeconds < 3 ? 0 : differenceInSeconds

        set({lastRecordedTime: now.toISOString()})
        localStorage.setItem('lastRecordedTime', now.toISOString())

        set({timerInSeconds: get().timerInSeconds + differenceToAdd + 1})
        localStorage.setItem('timerInSeconds', (get().timerInSeconds + differenceToAdd + 1).toString())
    },
    resumeTimer: () => {
        set({isTimerOn: true})
        localStorage.setItem('isTimerOn', 'true')

        set({lastRecordedTime: new Date().toISOString()})
        localStorage.setItem('lastRecordedTime', new Date().toISOString());
    },
    pauseTimer: () => {
        set({isTimerOn: false})
        localStorage.setItem('isTimerOn', 'false')

        set({lastRecordedTime: null})
        localStorage.removeItem('lastRecordedTime')
    },

    isTimerModalOpen: false,
    openTimerModal: () => set({isTimerModalOpen: true}),
    closeTimerModal: () => set({isTimerModalOpen: false}),

    isEndModalOpen: false,
    openEndModal: () => set({isEndModalOpen: true}),
    closeEndModal: () => set({isEndModalOpen: false}),

    isPlayConfetti: false,
    playConfetti: () => {
        set({isPlayConfetti: true})

        setTimeout(() => {
            set({isPlayConfetti: false})
        }, 10000)
    }
}))

export default useReadingSessionStore
