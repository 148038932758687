import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom'
import Root from './common/Root'
import NotFound from './common/NotFound'
import Error from './common/Error'
import IndexRoute from './routes/index/IndexRoute'
import PrivacyByDate from './routes/privacy/PrivacyByDate'
import OAuthRedirect from './routes/login/OAuthRedirect'
import SearchBookRoute from './routes/search/book/SearchBookRoute'
import AdminTipsRoute from './routes/admin/AdminTipsRoute'
import AdminRoute from './routes/admin/AdminRoute'
import LibraryDetailRoute from './routes/library/find/LibraryDetailRoute'
import CommunityRoute from './routes/community/CommunityRoute'
import LibraryRoute from './routes/library/find/LibraryRoute'
import BookRoute from './routes/book/BookRoute'
import LibraryNearRoute from './routes/library/near/LibraryNearRoute'
import CommunityTipsRoute from './routes/community/tips/CommunityTipsRoute'
import CommunityTipsDetailRoute from './routes/community/tips/CommunityTipsDetailRoute'
import CommunityPopularBooksRoute from './routes/community/popular-books/CommunityPopularBooksRoute'
import BookStatisticsRoute from './routes/book/statitics/BookStatisticsRoute'
import BookMineRoute from './routes/book/mine/BookMineRoute'
import LibraryMembershipDetailRoute from './routes/library/membership/LibraryMembershipDetailRoute'
import LibraryRegionRoute from './routes/library/location/LibraryRegionRoute'
import PopularBooksProviderRoute from './routes/community/popular-books/PopularBooksProviderRoute'
import LibraryAddMembershipRoute from './routes/library/membership/add/LibraryAddMembershipRoute'
import LoginRoute from './routes/login/LoginRoute'
import BookDetailRoute from './routes/book/detail/BookDetailRoute'
import BookGoalRoute from './routes/book/goal/BookGoalRoute'
import AddBookRoute from './routes/book/add/AddBookRoute'
import AddRoute from "./routes/AddRoute"
import BooksitoutProductsRoute from "./booksitout/BooksitoutProductsRoute"
import BooksitoutValuesRoute from "./booksitout/BooksitoutValuesRoute"
import BooksitoutProductDetailRoute from "./booksitout/BooksitoutProductDetailRoute"
import BookIsbnRoute from "./routes/book/isbn/BookIsbnRoute"
import BookEditRoute from "./routes/book/edit/BookEditRoute"
import ProfileRedirectRoute from "./routes/appUsers/profiles/ProfileRedirectRoute"
import ProfileRoute from "./routes/appUsers/profiles/ProfileRoute"
import SettingsRoute from "./routes/appUsers/settings/SettingsRoute"
import SettingsPersonalInfoRoute from "./routes/appUsers/settings/SettingsPersonalInfoRoute"
import SettingsProfileRoute from "./routes/appUsers/settings/SettingsProfileRoute"
import ProfileBooksRoute from "./routes/appUsers/profiles/ProfileBooksRoute"
import ProfileUsersListRoute from "./routes/appUsers/profiles/ProfileUsersListRoute"
import SettingsPersonalInfoAddOAuthRedirectRoute
    from "./routes/appUsers/settings/SettingsPersonalInfoAddOAuthRedirectRoute"
import ProfileBookDetailRoute from "./routes/appUsers/profiles/ProfileBookDetailRoute"
import LibraryRankingRoute from "./routes/library/ranking/LibraryRankingRoute"
import FaqRoute from "./routes/faq/FaqRoute"
import SettingsSearchRoute from "./routes/appUsers/settings/SettingsSearchRoute"
import PwaRoute from "./routes/community/PwaRoute";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<Root/>} errorElement={<Error/>}>
            <Route path='*' element={<NotFound/>}/>
            <Route path='/' element={<IndexRoute/>}/>

            <Route path='/values' element={<BooksitoutValuesRoute/>}/>

            <Route path='/faq' element={<FaqRoute/>}/>

            <Route path='/users' element={<ProfileUsersListRoute/>}/>
            <Route path='/users/:userId' element={<ProfileRoute/>}/>
            <Route path='/users/:userId/books' element={<ProfileBooksRoute/>}/>
            <Route path='/users/:userId/books/:bookId' element={<ProfileBookDetailRoute/>}/>
            <Route path='/users/me/redirect' element={<ProfileRedirectRoute/>}/>

            <Route path='/settings' element={<SettingsRoute/>}/>
            <Route path='/settings/personal-infos' element={<SettingsPersonalInfoRoute/>}/>
            <Route path='/settings/personal-infos/add-account/:provider' element={<SettingsPersonalInfoAddOAuthRedirectRoute/>}/>
            <Route path='/settings/profiles' element={<SettingsProfileRoute/>}/>
            <Route path='/settings/search' element={<SettingsSearchRoute/>}/>

            <Route path='/book' element={<BookRoute/>}/>
            <Route path='/book/mine' element={<BookMineRoute/>}/>
            <Route path='/book/mine/:bookId' element={<BookDetailRoute/>}/>
            <Route path='/book/mine/:bookId/edit' element={<BookEditRoute/>}/>
            <Route path='/book/goal' element={<BookGoalRoute/>}/>
            <Route path='/book/statistics' element={<BookStatisticsRoute/>}/>
            <Route path='/book/:isbn' element={<BookIsbnRoute/>}/>

            <Route path='/library' element={<LibraryRoute/>}/>
            <Route path='/library/near' element={<LibraryNearRoute/>}/>
            <Route path='/library/ranking' element={<LibraryRankingRoute/>}/>
            <Route path='/library/:libraryId' element={<LibraryDetailRoute/>}/>
            <Route path='/library/by-region/:region' element={<LibraryRegionRoute/>}/>
            <Route path='/library/by-region/:region/:regionDetail' element={<LibraryRegionRoute/>}/>
            <Route path='/library/membership' element={<LibraryAddMembershipRoute/>}/>
            <Route path='/library/membership/:membershipId' element={<LibraryMembershipDetailRoute/>}/>

            <Route path='/community' element={<CommunityRoute/>}/>
            <Route path='/community/tips' element={<CommunityTipsRoute/>}/>
            <Route path='/community/tips/:tipsId' element={<CommunityTipsDetailRoute/>}/>
            <Route path='/community/popular-books' element={<CommunityPopularBooksRoute/>}/>
            <Route path='/community/popular-books/:provider' element={<PopularBooksProviderRoute/>}/>

            <Route path='/search' element={<SearchBookRoute/>}/>

            <Route path='/login' element={<LoginRoute/>}/>
            <Route path='/login/oauth/:provider' element={<OAuthRedirect/>}/>

            <Route path='/admin' element={<AdminRoute/>}/>
            <Route path='/admin/tips/:id' element={<AdminTipsRoute/>}/>

            <Route path='/pwa' element={<PwaRoute/>}/>

            <Route path='/privacy/:date' element={<PrivacyByDate/>}/>
            <Route path='/products/:productName/privacy/:date' element={<PrivacyByDate/>}/>

            <Route path='/add' element={<AddRoute/>}>
                <Route path='book' element={<AddBookRoute/>}/>
                <Route path='membership' element={<LibraryAddMembershipRoute/>}/>
            </Route>

            {/* for booksitout */}
            <Route path='/products' element={<BooksitoutProductsRoute/>}/>
            <Route path='/products/:productName' element={<BooksitoutProductDetailRoute/>}/>
        </Route>
    ),
)

function App() {
    return (
        <div className='App'>
            <RouterProvider router={router}/>
        </div>
    )
}

export default App
