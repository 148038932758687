import React from 'react'
import styled from 'styled-components';
import ColorConfig from "../../../../../config/ColorConfig";
import breakpoints from "../../../../../config/Breakpoints";

interface Props {
    title: string
    subTitle?: string
    description?: string
    right?: JSX.Element

    body: () => JSX.Element
}

const BookSourceSection: React.FC<Props> = ({title, subTitle, description, right, body}) => {
    return (
        // @ts-ignore
        <Container>
            <TitleContainer>
                <TitleSubTitleContainer>
                    <Title>{title}</Title>

                    {
                        subTitle &&
                        <SubTitle>{subTitle}</SubTitle>
                    }
                </TitleSubTitleContainer>

                {right}
            </TitleContainer>

            {body}
        </Container>
    )
}

const Container = styled.div`
    padding: 20px;
    border-left: 5px solid ${ColorConfig.Primary};
    min-height: 200px;
`

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TitleSubTitleContainer = styled.div`
    display: flex;
`

const Title = styled.h3`
    padding-bottom: 10px;
`

const SubTitle = styled.div.attrs({
    className: 'text-secondary clamp-1-line'
})`
    margin-left: 10px;
    margin-top: 7px;

    max-width: 400px;

    @media (max-width: ${breakpoints.md}) {
        max-width: 200px;
    }
`;

export default BookSourceSection