import React from 'react'
import {Placeholder} from 'react-bootstrap';
import styled from "styled-components";

const BookReadingSessionButtonLoading = () => {
    return (
        <Button xs={4} aria-hidden="true"/>
    )
}

const Button = styled(Placeholder.Button).attrs({
    variant: 'book'
})`
    margin-top: 10px;
    width: 100%;

    max-width: 300px;
`;

export default BookReadingSessionButtonLoading
