import React from 'react'
import booksitoutIcon from "../../../../../config/BooksitoutIcon";

interface Props {
    isLoanPossible: boolean
    possibleMessage?: string
    impossibleMessage?: string
}

const LoanPossibleBadge: React.FC<Props> = ({isLoanPossible, possibleMessage, impossibleMessage}) => {
    return (
        <div className='m-0 p-0'>
            {
                isLoanPossible
                    ?
                    <booksitoutIcon.check className='text-book me-2 mb-1'/>
                    :
                    <booksitoutIcon.xmark
                        className='text-danger me-2 mb-1'/>
            }

            {isLoanPossible ? (possibleMessage ?? '대출가능') : (impossibleMessage ?? '대출불가')}
        </div>
    )
}
export default LoanPossibleBadge
