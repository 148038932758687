import React from 'react'
import Center from "./Center";
import RatingStar from "./RatingStar";

interface Props {
    rating: number
    size?: number
}

const BookRating: React.FC<Props> = ({rating, size}) => {
    return (
        <Center>
            {
                Array(rating).fill(0).map(_ => {
                    return <RatingStar isFilled={true} size={size ?? 50}/>
                })
            }

            {
                Array(5 - rating).fill(0).map(_ => {
                    return <RatingStar isFilled={false} size={size ?? 50}/>
                })
            }
        </Center>
    )
}
export default BookRating
