import React, {useState} from 'react'
import Modal from "../../../common/Modal";
import {Button, Form} from "react-bootstrap";
import styled from 'styled-components';
import DateForm from "../../../common/form/DateForm";
import RowSpacer from "../../../common/styles/RowSpacer";

interface Props {
    isModalOpen: boolean
    onClose: () => void
    updateStatusToDone: (year: number, month: number, day: number) => void
}

const BookDoneModal: React.FC<Props> = ({isModalOpen, onClose, updateStatusToDone}) => {
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [month, setMonth] = useState<number>(new Date().getMonth() + 1)
    const [day, setDay] = useState<number>(new Date().getDate())

    const onSubmit = (e) => {
        e.preventDefault()
        updateStatusToDone(year, month, day)
        onClose()
    }

    return (
        <Modal
            titleText={'책 다 읽음으로 변경'}
            isShowing={isModalOpen}
            onClose={onClose}
            body={
                <Form onSubmit={onSubmit}>
                    <Container>
                        <RowSpacer size={10}/>

                        <DateForm year={year} month={month} day={day}
                                  setYear={setYear} setMonth={setMonth} setDay={setDay}/>

                        <RowSpacer/>

                        <Button role={'submit'} variant={'book'} onClick={onSubmit}>책 상태 다 읽음으로 변경하기</Button>
                    </Container>
                </Form>
            }
        />
    )
}

const Container = styled.div.attrs({
    className: 'row'
})`
    padding-left: 20px;
    padding-right: 20px;
`

const Col = styled.div.attrs({
    className: 'col-4'
})

export default BookDoneModal
