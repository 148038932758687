import React from "react";
import styled from 'styled-components';
import {ProgressBar} from 'react-bootstrap'
import BookResponse from '../routes/book/BookResponse'
import ColSpacer from './styles/ColSpacer';

interface Props {
    book: BookResponse
    isShowPage?: boolean
}

const BookPageBar: React.FC<Props> = ({book, isShowPage = true}) => {
    return (
        <Container>
            <ProgressBarContainer>
                <ProgressBar
                    variant='book'
                    className='mt-3 mb-3'
                    now={calculateProgress(book)}
                    label={`${Math.round(calculateProgress(book))}%`}
                />
            </ProgressBarContainer>

            {isShowPage && (
                <>
                    <ColSpacer/>
                    <span className='force-1-line'>
                        <b className='text-book'>{`${book.currentPage == null || book.currentPage < 0 ? 0 : book.currentPage}`}</b> /{' '}
                        {`${book.endPage}`}
                    </span>
                </>
            )}
        </Container>
    )
}

const calculateProgress = (book: BookResponse) => {
    if (book.endPage === 0) {
        return book.status === 'DONE' ? 100 : 0;
    }
    const currentPage = book.currentPage == null ? 0 : book.currentPage;
    return (currentPage / book.endPage) * 100;
};

const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    align-items: center;
    text-align: center;
    justify-content: center;
`;

const ProgressBarContainer = styled.div`
    width: 100%;
`;

export default BookPageBar
