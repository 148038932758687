import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import IndexContentContainer from "../../../index/IndexContentContainer"
import BookReadingSessionResponse from "../../reading/BookReadingSessionResponse"
import CardAddButton from '../../../../common/button/CardAddButton';
import ContentContainer from "../../../../common/styles/ContentContainer";
import RowSpacer from "../../../../common/styles/RowSpacer";
import utils from "../../../../common/utils";
import BookDetailReadingSessionAddModal from "./BookDetailReadingSessionAddModal";
import BookDetailReadingSessionEditDeleteModal from "./BookDetailReadingSessionEditDeleteModal";
import useBookDetailStore from "../useBookDetailStore";
import BookResponse from "../../BookResponse";

const BookDetailReadingSessionCard = () => {
    const {book, readingSessions} = useBookDetailStore()

    const [isAddOpen, setIsAddOpen] = useState<boolean>(false)
    const [isEditOrDeleteOpen, setIsEditOrDeleteOpen] = useState<boolean>(false)
    const [selectedReadingSession, setSelectedReadingSession] = useState<BookReadingSessionResponse | null>(null)

    const openAdd = () => setIsAddOpen(true)
    const closeAdd = () => setIsAddOpen(false)
    const openEditOrDelete = (readingSession: BookReadingSessionResponse) => {
        setIsEditOrDeleteOpen(true)
        setSelectedReadingSession(readingSession)
    }
    const closeEditOrDelete = () => {
        setIsEditOrDeleteOpen(false)
        setSelectedReadingSession(null)
    }

    return (
        <IndexContentContainer>
            <BookDetailReadingSessionAddModal isOpen={isAddOpen} close={closeAdd}/>

            {
                selectedReadingSession != null &&
                <BookDetailReadingSessionEditDeleteModal
                    isOpen={isEditOrDeleteOpen}
                    close={closeEditOrDelete}
                    readingSession={selectedReadingSession}
                />
            }

            <CardAddButton onClick={openAdd}/>
            {
                book != null &&
                <CardReadingSessionSummary book={book} readingSessions={readingSessions}/>
            }

            <Container>
                <>
                    <RowSpacer/>
                    {
                        readingSessions.length > 0
                            ?
                            <NonEmptyCaseContainer>
                                {
                                    readingSessions
                                        .filter((readingSession) => readingSession.endTime != null)
                                        .map(readingSession =>
                                            <ReadingSessionContainer key={readingSession.id} height={100}
                                                                     onClick={() => openEditOrDelete(readingSession)}>
                                                <ReadingSessionTimeContainer>
                                                    {readingSession.readTimeInMinutes ?? '?'}분
                                                </ReadingSessionTimeContainer>

                                                <ReadingSessionPageContainer>
                                                    <Bold>{readingSession.startPage ?? '?'}</Bold>P
                                                    - <Bold>{readingSession.endPage ?? '?'}</Bold>P
                                                </ReadingSessionPageContainer>

                                                <ReadingSessionDateContainer>
                                                    {utils.formatTime(readingSession.startTime?.toString() ?? null) ?? '?'}
                                                </ReadingSessionDateContainer>

                                                <RowSpacer size={10}/>
                                            </ReadingSessionContainer>
                                        )
                                }
                            </NonEmptyCaseContainer>
                            :
                            <EmptyCase/>
                    }
                </>
            </Container>
        </IndexContentContainer>
    )
}

const EmptyCase = () => {
    return (
        <EmptyCaseContainer>
            <EmptyCaseText>📚 기록하신 독서 활동이 없어요!</EmptyCaseText>
        </EmptyCaseContainer>
    )
}

interface CardReadingSessionSummaryProps {
    book: BookResponse
    readingSessions: BookReadingSessionResponse[]
}

const CardReadingSessionSummary: React.FC<CardReadingSessionSummaryProps> = ({book, readingSessions}) => {
    const Container = styled.div`
        padding: 0;

        position: absolute;
        left: 10px;

        z-index: 10;

        display: flex;
        flex-direction: row;
    `

    const Badge = styled.div`
        color: white;
        background-color: gray;
        font-weight: bold;
        text-align: center;
        border-radius: 5px;
        padding: 5px 10px;
        width: 100px;
        
        margin-left: 5px;
        margin-right: 5px;
    `

    const [totalTimeInMinutes, setTotalTimeInMinutes] = useState(0)
    const [estimatedTimeInMinutes, setEstimatedTimeInMinutes] = useState('?')
    useEffect(() => {
        if (readingSessions != undefined) {
            let totalTime = readingSessions
                .reduce((acc, readingSession) => acc + (readingSession.readTimeInMinutes ?? 0), 0)

            let pagesForTotalTime = readingSessions
                .filter(readingSession => readingSession.startPage != null && readingSession.endPage != null)
                .reduce((acc, readingSession) => acc + (readingSession.endPage ?? 0) - (readingSession.startPage ?? 0), 0)

            let minutePerPage = totalTime / pagesForTotalTime

            let maxEndPage = Math.max(...readingSessions.map(session => session.endPage ?? 0))
            let remainPages = book.endPage - maxEndPage
            let estimatedRemainTimeInMinutes = Math.round(remainPages * minutePerPage)

            setTotalTimeInMinutes(totalTime)
            setEstimatedTimeInMinutes(estimatedRemainTimeInMinutes.toString() == 'NaN' ? '?' : estimatedRemainTimeInMinutes.toString())
        }
    }, [readingSessions]);

    return (
        <Container>
            <Badge>총 {totalTimeInMinutes}분</Badge>

            {
                book?.status != 'DONE' &&
                <Badge>{estimatedTimeInMinutes}분 남음</Badge>
            }
        </Container>
    )
}

const EmptyCaseContainer = styled.div`
    width: 100%;
    height: 80%;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: black;
`;

const EmptyCaseText = styled.h3`
    text-align: center;
`;

const Container = styled.div`
    min-height: 250px;
    width: 100%;
`;

const NonEmptyCaseContainer = styled.div.attrs({
    className: 'row'
})`
`

interface ReadingSessionContainerProps {
    onClick: (readingSession: BookReadingSessionResponse) => void
}

const ReadingSessionContainer = styled(ContentContainer).attrs({
    className: 'col-12 col-sm-6 col-md-4 col-xl-3'
})<ReadingSessionContainerProps>`
`

const ReadingSessionDateContainer = styled.div`
    position: absolute;
    right: 15px;
    bottom: 15px;
`

const ReadingSessionTimeContainer = styled.div`
    font-size: 40px;
`

const ReadingSessionPageContainer = styled.div`
    position: absolute;
    right: 15px;
    bottom: 40px;
`

const Bold = styled.span.attrs({
    className: 'text-book'
})`
    font-weight: bold;
`

export default BookDetailReadingSessionCard
