import React from 'react'
import {Button} from "react-bootstrap";
import BooksitoutIcon from "../../config/BooksitoutIcon";
import useLoginStore from "../login/useLoginStore";
import BookIsbnResponse from "./isbn/BookIsbnResponse";

interface Props {
    book: BookIsbnResponse
    onClick: () => void
}

const BookAddBadge: React.FC<Props> = ({book, onClick}) => {
    const isLoggedIn = useLoginStore((state) => state.isLoggedIn())

    return (
        <>
            {
                !book.isAdded ?
                    (
                        isLoggedIn ?
                            <Button variant={'book'} onClick={onClick}>
                                        <span className={'pe-1'}>
                                            <BooksitoutIcon.add/>
                                        </span>

                                <span className={'pt-1'}>
                                            내 서재에 추가
                                        </span>
                            </Button>
                            :
                            <a href={'/login'}>
                                <Button variant={'book'}>
                                            <span className={'pe-1'}>
                                                <BooksitoutIcon.add/>
                                            </span>

                                    <span className={'pt-1'}>
                                                내 서재에 추가
                                    </span>
                                </Button>
                            </a>
                    )
                    :
                    <Button variant={'book'} disabled>
                        <span className={'pe-1'}>
                            <BooksitoutIcon.check/>
                        </span>

                        <span className={'pt-1'}>
                                    추가됨
                        </span>
                    </Button>
            }</>
    )
}
export default BookAddBadge
