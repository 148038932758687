import {useEffect, useState} from "react"
import {BooksitoutServer} from "../../../../config/BooksitoutServer"
import {BookSourceSubscription, BookSourceSubscriptionValues} from "../BookSource";

const useBookSubscription = (isbn13: string) => {
    const [books, setBooks] = useState<BookSubscriptionResponse[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (isbn13 !== '') {
            BooksitoutServer
                .get(`/v1/book/search/sources/subscription?isbn13=${isbn13}&sources=${Array.of(BookSourceSubscriptionValues).join(",")}`)
                .then((res) => setBooks(res.data))
                .finally(() => setIsLoading(false))
        }
    }, [isbn13])

    return [books, isLoading] as const
}

interface BookSubscriptionResponse {
    link: string
    provider: BookSourceSubscription
}

export default useBookSubscription
