import React from 'react'
import styled from 'styled-components';
import Modal from '../../../common/Modal'
import useBookDone from "./useBookDone";
import NoContent from "../../../common/NoContent";
import BookMineDoneCard from "../mine/BookMineDoneCard";
import BookMineLoadingCard from "../mine/BookMineLoadingCard";

interface Props {
    year: number
    isOpen: boolean
    close: () => void
}

const BookGoalDoneBookModal: React.FC<Props> = ({year, isOpen, close}) => {
    return (
        <Modal
            size={'xl'}
            isShowing={isOpen}
            onClose={close}
            titleText={`${year}년도에 다 읽은 책`}
            body={<Body year={year}/>}
        />
    )

}

interface BodyProps {
    year: number
}

const Body: React.FC<BodyProps> = ({year}) => {
    const [isLoading, isError, books] = useBookDone(year)

    if (isLoading) {
        return (
            <Row>
                {
                    Array.from(({length: 12})).map(() =>
                        <Col>
                            <BookMineLoadingCard/>
                        </Col>
                    )
                }
            </Row>
        )
    }

    if (isError) {
        return <NoContent message={`오류가 났어요`}/>
    }

    if (books.length == 0) {
        return (
            <NoContentContainer>
                <NoContent message={`${year}년에는 읽은 책이 없어요`}/>
            </NoContentContainer>
        )
    }

    return (
        <Row>
            {
                books.map(book =>
                    <Col>
                        <BookMineDoneCard book={book}/>
                    </Col>
                )
            }
        </Row>
    );
}

const Row = styled.div.attrs({
    className: 'row row-eq-height ps-2 pe-2'
})``

const Col = styled.div.attrs({
    className: 'col-6 col-md-4 col-lg-3 col-xl-2'
})``

const NoContentContainer = styled.div`
    min-height: 200px;
`

export default BookGoalDoneBookModal
