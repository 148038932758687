import React from 'react'
import {Card, Table as BootstrapTable} from "react-bootstrap";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import Spacer from "../../../common/Spacer";
import styled from "styled-components";
import BooksitoutImages from "../../../images/BooksitoutImages";
import LoadingBar from "../../../common/LoadingBar";

const BookIsbnCardLoading = () => {
    return (
        <Card>
            <CardBodyContentContainer height={400}>
                <Container>
                    <BookCoverContainer>
                        <BookCover src={BooksitoutImages.Placeholder.bookCoverLoading} alt={'로딩중'}/>
                    </BookCoverContainer>

                    <BookInfoContainer>
                        <div>
                            <BookTitle><LoadingBar size={8}/></BookTitle>
                            <BookAuthor><LoadingBar size={3}/></BookAuthor>
                        </div>

                        <Spacer/>

                        <Table hover>
                            <tbody>
                            <tr>
                                <td><InfoTitle>출판사</InfoTitle></td>
                                <td><InfoContent></InfoContent></td>
                            </tr>

                            <tr>
                                <td><InfoTitle>페이지</InfoTitle></td>
                                <td><InfoContent>쪽</InfoContent></td>
                            </tr>

                            <tr>
                                <td><InfoTitle>출판년도</InfoTitle></td>
                                <td><InfoContent>년</InfoContent></td>
                            </tr>
                            </tbody>
                        </Table>

                        <Spacer/>

                        <div>
                        </div>
                    </BookInfoContainer>
                </Container>
            </CardBodyContentContainer>
        </Card>
    )
}

const Container = styled.div.attrs({
    className: 'row'
})`
    height: 100%;
    padding: 20px;
`

const BookCoverContainer = styled.div.attrs({
    className: 'col-12 col-sm-6 col-md-4 col-xl-3'
})`
    height: 100%;
    align-content: center;
`

const BookInfoContainer = styled.div.attrs({
    className: 'col-12 col-sm-6 col-md-8 col-xl-9'
})`
    padding-left: 30px;
    padding-top: 30px;

    display: flex;
    flex-direction: column;
`

const BookCover = styled.img.attrs({
    className: 'img-fluid rounded border'
})``

const BookTitle = styled.h1`
    font-size: 2rem;
    font-weight: bold;
`

const BookAuthor = styled.h2.attrs({
    className: 'text-secondary'
})`
    font-size: 1.5rem;
    font-weight: bold;
`

const InfoTitle = styled.td``

const InfoContent = styled.td.attrs({
    className: 'text-secondary'
})``

const Table = styled(BootstrapTable)`
    max-width: 500px;
`

export default BookIsbnCardLoading
