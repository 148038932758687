import {useEffect, useState} from "react";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";
import SearchSettingsResponse from "../../../appUsers/settings/response/BookSearchSettingsResponse";
import useLoginStore from "../../../login/useLoginStore";

const useBookSearchSettings = () => {
    const [settings, setSettings] = useState<SearchSettingsResponse | null>(null)

    const isLoggedIn = useLoginStore(state => state.isLoggedIn())
    useEffect(() => {
        fetchSettings()
    }, [isLoggedIn])

    const fetchSettings = () => {
        if (isLoggedIn) {
            BooksitoutServer
                .get(`/v1/book/search/settings`)
                .then((res) => setSettings(res.data))
        }
    }

    const refresh = () => {
        fetchSettings()
    }

    return [settings, refresh] as const
}

export default useBookSearchSettings