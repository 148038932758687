import BookGoalResponse from "./BookGoalResponse";
import {create} from "zustand";

interface BookGoalStore {
    goals: BookGoalResponse[]

    getGoal: (year: number) => BookGoalResponse | null
    addGoal: (goal: BookGoalResponse) => void
    deleteGoal: (year: number) => void

    init: (goals: BookGoalResponse[]) => void
}

const useBookGoalStore = create<BookGoalStore>((set, get) => ({
    goals: [],

    getGoal: (year) => {
        const goal = get().goals.find(g => g.year === year)
        return goal ? goal : null
    },
    addGoal: (goal) => set(state => ({goals: [...state.goals, goal]})),
    deleteGoal: (year) => set(state => ({goals: state.goals.filter(g => g.year != year)})),

    init: (goals) => set({goals})
}))

export default useBookGoalStore