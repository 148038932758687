import React from 'react'
import {FaStar} from "react-icons/fa6";
import {FaRegStar} from "react-icons/fa6";

interface Props {
    size: number
    isFilled: boolean
}

const RatingStar: React.FC<Props> = ({size, isFilled}) => {
    if (isFilled) {
        return (
            <div className={`text-book`} style={{fontSize: size}}>
                <FaStar/>
            </div>
        )
    }

    return (
        <div className={`text-secondary`} style={{fontSize: size}}>
            <FaRegStar/>
        </div>
    )
}

export default RatingStar
