import React from 'react'
import styled from 'styled-components';
import {Card} from "react-bootstrap";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import ColSpacer from "../../../common/styles/ColSpacer";
import AppUserResponse from "../AppUserResponse";
import BooksitoutImages from "../../../images/BooksitoutImages";
import BooksitoutIcon from "../../../config/BooksitoutIcon";
import Spacer from '../../../common/Spacer';

interface Props {
    user: AppUserResponse
}

const ProfileUserCard: React.FC<Props> = ({user}) => {

    return (
        <a href={`/users/${user.id}`}>
            <Card>
                <CardBodyContentContainer>
                    <ProfileContainer>
                        <Flex>
                            {
                                user.profileImageUrl != null
                                    ?
                                    <ProfileImage src={user.profileImageUrl}
                                                  alt={user.name}/>
                                    :
                                    <ProfileImage src={BooksitoutImages.Placeholder.user} alt={user.name}/>
                            }

                            <ColSpacer/>

                            <div>
                                <ProfileName>{user.name}</ProfileName>

                                <ProfileDate>
                                    {user.registeredDate[0]}년
                                    {' '}
                                    {user.registeredDate[1]}월
                                    {' '}
                                    {user.registeredDate[2]}일
                                </ProfileDate>
                            </div>
                        </Flex>

                        <Spacer/>

                        {
                            user.isMe &&
                            <a href={'/settings/profiles'}>
                                <h3 className={'text-book'}>
                                    <BooksitoutIcon.settings/>
                                </h3>
                            </a>
                        }
                    </ProfileContainer>
                </CardBodyContentContainer>
            </Card>
        </a>
    )
}

const Flex = styled.div`
    display: flex;
`

const ProfileContainer = styled.div`
    display: flex;
`

const ProfileName = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
`

const ProfileDate = styled.h1.attrs({
    className: 'text-secondary'
})`
    font-size: 1rem;
`

const ProfileImage = styled.img.attrs({
    className: 'rounded'
})`
    width: 100px;
    height: 100px;
`

export default ProfileUserCard
