import NoContent from "./NoContent"
import RowSpacer from "./styles/RowSpacer";

const NotFound = () => {
    return (
        <>
            <RowSpacer/>
            <NoContent message='없는 페이지에요'/>
            <RowSpacer/>
        </>
    )
}

export default NotFound