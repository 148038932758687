import React from 'react'
import styled from 'styled-components';
import RouteContainer from "../../../common/styles/RouteContainer";
import useLibraryByBookCount from "../useLibraryByBookCount";
import RouteTitle from "../../../common/RouteTitle/RouteTitle";
import booksitoutIcon from "../../../config/BooksitoutIcon";
import RouteTitleConfig from "../../../config/RouteTitleConfig";
import LibraryCardLoading from "../find/LibraryCardLoading";
import LibraryCard from "../find/LibraryCard";
import RowSpacer from "../../../common/styles/RowSpacer";
import InfiniteScroll from "react-infinite-scroll-component";

const LibraryRankingRoute = () => {
    const [libraries, isLoading, _, paging] = useLibraryByBookCount(20)

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.library/>}
                title={'책 많은 도서관 순위'}
                subTitle={'책이 많은 순서대로 도서관을 둘러 볼 수 있어요'}
                currentKey={'library'}
                buttons={RouteTitleConfig.Library}
            />

            <RowSpacer/>
            {
                isLoading
                    ?
                    <LibraryContainer>
                        <LibraryLoading size={8}/>
                    </LibraryContainer>
                    :
                    <InfiniteScroll
                        next={paging.fetchNext}
                        hasMore={paging.hasMore}
                        dataLength={libraries.length}
                        loader={<LibraryLoading size={4}/>}
                    >
                        <LibraryContainer>
                            {
                                libraries.map(library =>
                                    <Col>
                                        <LibraryCard library={library}/>
                                    </Col>
                                )
                            }
                        </LibraryContainer>
                    </InfiniteScroll>
            }
        </RouteContainer>
    )
}

interface LibraryLoadingProps {
    size: number
}

const LibraryLoading: React.FC<LibraryLoadingProps> = ({size}) => {
    return (
        <>
            {
                Array.from({length: size}).map(_ =>
                    <Col>
                        <LibraryCardLoading/>
                    </Col>
                )
            }
        </>
    )
}

const LibraryContainer = styled.div.attrs({
    className: 'ps-2 pe-2'
})``

const Col = styled.div``

export default LibraryRankingRoute
