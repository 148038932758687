import React from 'react'
import styled, {keyframes} from 'styled-components'

interface Props {
    size: number
}

const glow = keyframes`
    0% { background-color: #e0e0e0; }
    40% { background-color: #d6d6d6; }
    60% { background-color: #c8c8c8; }
    100% { background-color: #e0e0e0; }
`;

const LoadingBarWrapper = styled.div<{ size: number }>`
    width: ${(props) => props.size * 30}px;
    height: 1em;
    background-color: #e0e0e0;
    border-radius: 2px;
    animation: ${glow} 2s infinite;
    margin-bottom: 0;
    cursor: wait; 
`;

const LoadingBar: React.FC<Props> = ({size}) => {
    return <LoadingBarWrapper size={size}/>;
};

export default LoadingBar;