import styled from 'styled-components';
import useBookLastRead from './useBookLastRead'
import BookReadingSessionButton from "./reading/BookReadingSessionButton";
import BookPageBar from "../../common/BookPageBar";
import React from "react";
import BooksitoutButton from "../../common/button/BooksitoutButton";
import LoadingBar from "../../common/LoadingBar";
import BookPageBarLoading from "../../common/BookPageBarLoading";
import BooksitoutImages from "../../images/BooksitoutImages";
import BookReadingSessionButtonLoading from "./reading/BookReadingSessionButtonLoading";

const BookLastReadCard = () => {
    const [book, isLoading] = useBookLastRead()

    if (isLoading) {
        return (
            <Row>
                <ImageContainer>
                    {/* @ts-ignore */}
                    <Image src={BooksitoutImages.Placeholder.bookCoverLoading}/>
                </ImageContainer>

                <InfoContainer>
                    <Title><LoadingBar size={8}/></Title>
                    <Author><LoadingBar size={3}/></Author>
                    <BookPageBarLoading/>
                    <BookReadingSessionButtonLoading/>
                </InfoContainer>
            </Row>
        )
    }

    if (!book) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <p className="text-center h6 pb-3">최근에 읽은 책이 없어요 🥺</p>
                <BooksitoutButton message="책 읽으러 가기" url={'/book/mine?range=READING'}/>
            </div>)
    }

    return (
        <Row href={`/book/mine/${book.id}`}>
            <ImageContainer>
                {/* @ts-ignore */}
                <Image src={book.cover}/>
            </ImageContainer>

            <InfoContainer>
                <Title>{book.title}</Title>
                <Author>{book.author}</Author>
                <BookPageBar book={book}/>
                <BookReadingSessionButton book={book} size={300}/>
            </InfoContainer>
        </Row>
    )
}

const Row = styled.a.attrs({
    className: 'row'
})`
    width: 100%;
`;

const ImageContainer = styled.div.attrs({
    className: 'col-4'
})`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const InfoContainer = styled.div.attrs({
    className: 'col-8'
})`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;

    &:hover {
        color: black;
    }
`;

const Image = styled.img.attrs({
    className: 'img-fluid rounded-3'
})`
    width: auto;
    max-height: 155px;
    object-fit: contain;
`;

const Title = styled.h5.attrs({
    className: 'clamp-1-line'
})`
`;

const Author = styled.h6.attrs({
    className: 'text-secondary'
})`
`;

export default BookLastReadCard
