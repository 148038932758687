import React, {useState} from 'react'
import BookMemoResponse from "./BookMemoResponse";
import toast from "react-hot-toast";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";
import Modal from "../../../../common/Modal";
import {Form} from "react-bootstrap";
import RowSpacer from "../../../../common/styles/RowSpacer";
import TextForm from "../../../../common/form/TextForm";
import AddButton from "../../../../common/button/AddButton";
import NumberInput from "../../../../common/form/NumberInput";
import useBookDetailStore from "../useBookDetailStore";

interface Props {
    isOpen: boolean
    close: () => void
    memo: BookMemoResponse | null
}

const BookDetailMemoEditModal: React.FC<Props> = ({isOpen, close, memo}) => {
    const {book} = useBookDetailStore()

    const [page, setPage] = useState<number | null>(memo?.page ?? null)
    const [content, setContent] = useState<string>(memo?.content ?? '')

    const editMemo = () => {
        if (page == memo?.page && content == memo?.content) {
            toast.error('수정할 내용이 없어요.')
            return
        }

        if (content === '') {
            toast.error('메모 내용을 입력해주세요.')
            return
        }

        const data = {
            page: page,
            content: content,
        }

        if (memo != null) {
            BooksitoutServer
                .put(`/v1/book/memo/${memo.id}`, data)
                .then(() => {
                    toast.success(`메모를 수정했어요.`)
                    close()
                    window.location.reload()
                })
                .catch(() => toast.error(`오류가 났어요. 잠시 후 다시 시도해 주세요.`))
        }
    }

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

    }

    if (memo == null) {
        return <></>
    }

    return (
        <Modal
            isShowing={isOpen}
            onClose={close}
            titleText={'✏️ 메모 수정하기'}
            size={'lg'}
            isPreventClose={true}
            body={
                <Form onSubmit={handleOnSubmit}>
                    <Form.Group>
                        <NumberInput
                            placeholder="페이지 번호"
                            onChange={(e) => setPage(Number(e.target.value))}
                            defaultValue={memo?.page ?? ''}
                            min={1}
                            max={book?.endPage ?? 10_000}
                        />
                    </Form.Group>

                    <RowSpacer size={10} />

                    <Form.Group>
                        <TextForm placeholder={'메모 내용'} initialContent={memo.content} setContent={setContent} height={300}/>
                    </Form.Group>

                    <RowSpacer size={10} />

                    <AddButton label={'수정하기'} onClick={editMemo} />
                </Form>
            }
        />
    )
}
export default BookDetailMemoEditModal
