import React, {useEffect, useRef} from 'react'
import {Form} from 'react-bootstrap'

interface Props extends React.ComponentPropsWithoutRef<typeof Form.Control> {
    isFocusOnAppear?: boolean
    height?: number
}

const TextInput: React.FC<Props> = (props) => {

    const htmlInputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (htmlInputRef.current && props.isFocusOnAppear == true) {
            htmlInputRef.current.focus()
        }
    }, []);

    return (
        <Form.Control
            type={'text'}
            style={{height: props.height}}
            ref={htmlInputRef}
            {...props}
        />
    )
}

export default TextInput
