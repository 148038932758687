import {useEffect, useState} from "react";
import BookResponse from "../BookResponse";
import {BooksitoutServer} from "../../../config/BooksitoutServer";

const useBookDone = (year: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)

    const [books, setBooks] = useState<BookResponse[]>([])

    useEffect(() => {
        BooksitoutServer
            .get(`/v1/book/my-book?range=DONE&page=1&size=200&year=${year}`)
            .then(res => setBooks(res.data.content))
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false))
    }, [year])

    return [isLoading, isError, books] as const
}

export default useBookDone