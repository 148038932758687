import React from "react";
import styled from 'styled-components';
import ColorConfig from '../config/ColorConfig';
import RowSpacer from './styles/RowSpacer';
import OAuthConfig from '../config/OAuthConfig';
import BooksitoutImages from "../images/BooksitoutImages";

interface Props {
    message: string | null
}

const Login: React.FC<Props> = ({message}) => {
    return (
        <NoLoginContainer>
            <Title>{message ?? '10초만에 로그인해 이용하기'}</Title>
            <RowSpacer/>

            <OAuthContainer>
                {
                    OAuthConfig.map(oauth =>
                        <a href={oauth.redirectUrl} style={{pointerEvents: !oauth.active ? 'none' : 'auto'}}>
                            <OAuthButton src={oauth.image} alt={`${oauth.displayName}`} active={oauth.active}/>
                        </a>
                    )
                }
            </OAuthContainer>
            <RowSpacer/>

            <ImageContainer>
                <Image src={BooksitoutImages.Illustration.relax} alt="login"/>
            </ImageContainer>
            <RowSpacer/>

            <p className={'text-secondary p-0 m-0'}>
                책잇아웃에 로그인하면 <a href={'https://booksitout.com/privacy/2023-07-01?language=KOREAN'} className={'text-book fw-bold'}>개인정보처리방침</a>에 동의한 것으로 간주돼요.

                <div className={'d-none d-md-block'}></div>

                {' '}왜 아이디/비밀번호 로그인이 없는지는 <a href={'https://booksitout.com/faq#1?language=KOREAN'} className={'text-book fw-bold'}>자주묻는질문</a>을
                참고해 주세요.
            </p>
        </NoLoginContainer>

    )
}

const NoLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const Title = styled.h1`
    color: ${ColorConfig.PrimaryDark};
    font-size: 35px;
    font-weight: 700;
    padding-top: 3px;
`;

const OAuthContainer = styled.div`
    text-align: center;
`;

interface OAuthButtonProps {
    active: boolean
}

const OAuthButton = styled.img.attrs((props) => ({
    // @ts-ignore
    className: `img-fluid ms-2 me-2 ms-md-3 me-md-3 rounded ${(!props.active && 'opacity-50')} hover-effect`
}))<OAuthButtonProps>`
    width: 50px;
`;

const ImageContainer = styled.div`
    min-height: 300px;
    justify-content: center;
    text-align: center;
`

const Image = styled.img.attrs({
    className: '',
})`
    height: 100%;
    width: 100%;
`

export default Login