import React from 'react'
import styled from 'styled-components';
import {Card, Placeholder} from "react-bootstrap";
import StatisticsTableRow from "./StatisticsTableRow";

interface Props {
    isLoading: boolean
    rows: StatisticsTableRow[]
}

const StatisticsTable: React.FC<Props> = ({isLoading, rows}) => {
    return (
        <Table>
            <tbody>
            {
                rows.map((row) => (
                    <tr>
                        <th className={'col-8 h5'}>
                            <Image src={row.icon} alt={''}/>
                            {row.name}
                        </th>

                        <td className={'col-4'}>
                            <h5>
                                {
                                    isLoading ?
                                        (
                                        <Placeholder as={Card.Text} animation='wave'>
                                            <Placeholder xs='6'/>
                                        </Placeholder>

                                        )
                                        :
                                        (
                                            row.value
                                        )
                                }
                            </h5>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </Table>
    )
}

const Table = styled.table.attrs({
    className: 'table table-hover'
})`
    margin: 0;
`;

const Image = styled.img.attrs({
    className: 'img-fluid me-3 force-1-line'
})`
    width: 30px;
    height: 30px;
`;

export default StatisticsTable
