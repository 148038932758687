import React from 'react'
import styled from "styled-components";
import BookResponse from "./BookResponse";
import {Button as BootstrapButton} from 'react-bootstrap'
import {BooksitoutServer} from '../../config/BooksitoutServer';
import toast from "react-hot-toast";
import useBookDetailStore from "./detail/useBookDetailStore";
import booksitoutIcon from "../../config/BooksitoutIcon";

interface Props {
    book: BookResponse
    openDateModal: () => void
}

const BookDoneButton: React.FC<Props> = ({book, openDateModal}) => {
    const {updateBookStatus} = useBookDetailStore()

    const onClick = (e) => {
        e.preventDefault()

        const isDone = book.status != 'DONE'

        if (isDone) {
            openDateModal()
            return
        }

        if (!window.confirm("책을 읽고 있음으로 바꿀까요?")) {
            return
        }

        BooksitoutServer
            .put(`/v1/book/${book.id}`, {status: 'READING'})
            .then(() => {
                updateBookStatus('READING')
                toast.success('책을 "다시 읽고 있음"으로 바꿨어요.')
            })
            .catch(() => toast.error(`오류가 났어요. 잠시 후 다시 시도해 주세요.`))
    }

    if (book.status == 'DONE') {
        return (
            // @ts-ignore
            <ButtonContainer onClick={onClick}>
                <BackButton/>
            </ButtonContainer>
        )
    }

    return (
        // @ts-ignore
        <Button onClick={onClick} isGiveUp={book.isGiveUp}>
            다 읽음으로
        </Button>
    )
}

const ButtonContainer = styled(BootstrapButton).attrs({
    className: 'clickable',
    variant: 'book'
})`
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 0;
`;

const Button = styled(BootstrapButton).attrs({
    variant: 'book-danger'
})`
    margin-top: 10px;
    width: 100%;
`;

const BackButton = styled(booksitoutIcon.back)`
    padding: 0;

    position: relative;
    bottom: 2px;
    left: 0.25px;
`;

export default BookDoneButton
