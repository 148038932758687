
import BooksitoutDemoImage from "../demos/BooksitoutDemoImage";
import BooksitoutProductType from "../../../booksitout/type/BooksitoutProductType";
import BooksitoutProductStatus from "../../../booksitout/type/BooksitoutProductStatus";
import BooksitoutImages from "../../../images/BooksitoutImages";

const booksitoutProductFeaturedDays: BooksitoutProductType = {
    id: 'featuredDays',
    status: BooksitoutProductStatus.PRODUCTION,
    name: {
        english: "Featured Days",
        url: "featured-days"
    },
    icon: BooksitoutImages.WebsiteLogo.featuredDays,
    link: [
        {
            link: "https://apps.apple.com/app/featured-days/id6523433258",
            icon: BooksitoutImages.WebsiteLogo.appStore,
            border: false
        },
    ],
    thumbnailImage: BooksitoutDemoImage.featuredDays,
    detailImage: BooksitoutDemoImage.featuredDays,
    featuresCount: 3,
    demoImages: [],
    privacyPolicyDates: ['2024-08-25'],
}

export default booksitoutProductFeaturedDays