import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import {useParams} from "react-router";
import useBookDetail from "../detail/useBookDetail";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {Button, Card, Form} from "react-bootstrap";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RouteContainer from "../../../common/styles/RouteContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import NumberInput from "../../../common/form/NumberInput";

const BookEditRoute = () => {
    const navigate = useNavigate()

    const {bookId} = useParams()
    const [book, isLoading] = useBookDetail(Number(bookId))

    const editBookToServer = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const data = {
            title: title,
            author: author,
            page: endPage,
            isPublic: isPublic,
        }

        BooksitoutServer
            .put(`/v1/book/${bookId}`, data)
            .then(() => {
                toast.success(`책을 수정했어요.`)
                navigate(`/book/mine/${bookId}`)
            })
    }

    const [title, setTitle] = useState<string>(book?.book.title || "")
    const [author, setAuthor] = useState<string>(book?.book.author || "")
    const [endPage, setEndPage] = useState<string>(book?.book.endPage.toString() || "")
    const [isPublic, setIsPublic] = useState<boolean>(book?.book.isPublic ?? true)

    useEffect(() => {
        if (book != null) {
            setTitle(book.book.title)
            setAuthor(book.book.author)
            setEndPage(book.book.endPage.toString())
            setIsPublic(book.book.isPublic)
        }
    }, [book]);

    if (isLoading) {
        return <></>
    }

    return (
        <RouteContainer>
            <RowSpacer size={10}/>
            <Form onSubmit={editBookToServer}>
                <Card>
                    <CardBodyContentContainer>
                        <Form.Group>
                            <Form.Label>제목</Form.Label>
                            <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                        </Form.Group>
                        <RowSpacer size={10}/>

                        <Form.Group>
                            <Form.Label>저자</Form.Label>
                            <Form.Control type="text" value={author} onChange={(e) => setAuthor(e.target.value)}/>
                        </Form.Group>
                        <RowSpacer size={10}/>

                        <Form.Group>
                            <Form.Label>페이지</Form.Label>
                            <NumberInput
                                value={endPage}
                                min={1}
                                max={10_000}
                                onChange={(e) => setEndPage(e.target.value)}
                            />
                        </Form.Group>
                        <RowSpacer size={10}/>

                        <Form.Group className='mb-5' controlId='formBasicCheckbox'>
                            <Form.Check
                                type='switch'
                                label='내 책 정보 공개하기'
                                checked={isPublic}
                                onChange={() => setIsPublic(!isPublic)}
                                className='force-1-line'
                            />
                        </Form.Group>

                        <RowSpacer/>
                        <Row>
                            <ButtonContainer>
                                <Button type={'submit'} variant={'book'} className={'w-100'}>책 수정하기</Button>
                            </ButtonContainer>
                        </Row>
                    </CardBodyContentContainer>
                </Card>
            </Form>
            <RowSpacer/>
        </RouteContainer>
    )
}

const Row = styled.div.attrs({
    className: 'row'
})`
    justify-content: right;
`

const ButtonContainer = styled.div.attrs({
    className: 'col-12 col-md-6'
})``

export default BookEditRoute
