import BooksitoutImages from "../images/BooksitoutImages";

const UrlEncoded = {
    SLASH: '%2F', // `/`
    COLON: '%3A' // ':'
}

const kakaoInfo = {
    clientId: 'e0b8e02a9826e15029e2182d1d03bf2b',
    responseType: 'code',
    redirectUrl: `https${UrlEncoded.COLON}${UrlEncoded.SLASH}${UrlEncoded.SLASH}booksitout.com${UrlEncoded.SLASH}login${UrlEncoded.SLASH}oauth${UrlEncoded.SLASH}kakao${UrlEncoded.SLASH}`,
    addToExistingUrl: `https${UrlEncoded.COLON}${UrlEncoded.SLASH}${UrlEncoded.SLASH}booksitout.com${UrlEncoded.SLASH}settings${UrlEncoded.SLASH}personal-infos${UrlEncoded.SLASH}add-account${UrlEncoded.SLASH}kakao`,
}

const naverInfo = {
    clientId: 'WWI0nkWyzfAIMmjR0Y8N',
    responseType: 'code',
    redirectUrl: `https${UrlEncoded.COLON}${UrlEncoded.SLASH}${UrlEncoded.SLASH}booksitout.com${UrlEncoded.SLASH}login${UrlEncoded.SLASH}oauth${UrlEncoded.SLASH}naver${UrlEncoded.SLASH}`,
    addToExistingUrl: `https${UrlEncoded.COLON}${UrlEncoded.SLASH}${UrlEncoded.SLASH}booksitout.com${UrlEncoded.SLASH}settings${UrlEncoded.SLASH}personal-infos${UrlEncoded.SLASH}add-account${UrlEncoded.SLASH}naver`,
    state: 'bookitout',
}

const googleInfo = {
    clientId: '1006818294840-ukep9b2djha66u8on652mjkmmi93q94h.apps.googleusercontent.com',
    responseType: 'code',
    redirectUrl: `https${UrlEncoded.COLON}${UrlEncoded.SLASH}${UrlEncoded.SLASH}booksitout.com${UrlEncoded.SLASH}login${UrlEncoded.SLASH}oauth${UrlEncoded.SLASH}google`,
    addToExistingUrl: `https${UrlEncoded.COLON}${UrlEncoded.SLASH}${UrlEncoded.SLASH}booksitout.com${UrlEncoded.SLASH}settings${UrlEncoded.SLASH}personal-infos${UrlEncoded.SLASH}add-account${UrlEncoded.SLASH}google`,
    scope: `https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`,
}

const appleInfo = {
    clientId: `com.booksitout.id`,
    redirectUrl: `https${UrlEncoded.COLON}${UrlEncoded.SLASH}${UrlEncoded.SLASH}booksitout.com${UrlEncoded.SLASH}login${UrlEncoded.SLASH}oauth${UrlEncoded.SLASH}apple`,
    addToExistingUrl: `https${UrlEncoded.COLON}${UrlEncoded.SLASH}${UrlEncoded.SLASH}booksitout.com${UrlEncoded.SLASH}settings${UrlEncoded.SLASH}personal-infos${UrlEncoded.SLASH}add-account${UrlEncoded.SLASH}apple`,
}

const OAuthConfig = [
    {
        id: 1,
        image: BooksitoutImages.LoginButton.google,
        redirectUrl: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleInfo.clientId}&redirect_uri=${googleInfo.redirectUrl}&response_type=${googleInfo.responseType}&scope=${googleInfo.scope}`,
        addToExistingUrl: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleInfo.clientId}&redirect_uri=${googleInfo.addToExistingUrl}&response_type=${googleInfo.responseType}&scope=${googleInfo.scope}`,
        displayName: `구글 로그인`,
        active: true,
    },
    {
        id: 5,
        image: BooksitoutImages.LoginButton.apple,
        redirectUrl: `https://appleid.apple.com/auth/authorize?client_id=${appleInfo.clientId}&response_mode=query&response_type=code&state=booksitout&redirect_uri=${appleInfo.redirectUrl}`,
        addToExistingUrl: `https://appleid.apple.com/auth/authorize?client_id=${appleInfo.clientId}&response_mode=query&response_type=code&state=booksitout&redirect_uri=${appleInfo.addToExistingUrl}`,
        displayName: `애플 로그인`,
        active: true,
    },
    {
        id: 3,
        image: BooksitoutImages.LoginButton.kakao,
        redirectUrl: `https://kauth.kakao.com/oauth/authorize?client_id=${kakaoInfo.clientId}&redirect_uri=${kakaoInfo.redirectUrl}&response_type=${kakaoInfo.responseType}`,
        addToExistingUrl: `https://kauth.kakao.com/oauth/authorize?client_id=${kakaoInfo.clientId}&redirect_uri=${kakaoInfo.addToExistingUrl}&response_type=${kakaoInfo.responseType}`,
        displayName: `카카오 로그인`,
        active: true,
    },
    {
        id: 4,
        image: BooksitoutImages.LoginButton.naver,
        redirectUrl: `https://nid.naver.com/oauth2.0/authorize?client_id=${naverInfo.clientId}&redirect_uri=${naverInfo.redirectUrl}&response_type=${naverInfo.responseType}&state=bookitout&version=js-2.0.1`,
        addToExistingUrl: `https://nid.naver.com/oauth2.0/authorize?client_id=${naverInfo.clientId}&redirect_uri=${naverInfo.addToExistingUrl}&response_type=${naverInfo.responseType}&state=bookitout&version=js-2.0.1`,
        displayName: `네이버 로그인`,
        active: true,
    },
]

export default OAuthConfig