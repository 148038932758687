import Modal from '../../../common/Modal';
import {Form} from 'react-bootstrap';
import React, {useState} from 'react';
import AddButton from '../../../common/button/AddButton';
import RowSpacer from '../../../common/styles/RowSpacer';
import {BooksitoutServer} from '../../../config/BooksitoutServer';
import toast from 'react-hot-toast';
import NumberInput from "../../../common/form/NumberInput";
import useBookGoalStore from "./useBookGoalStore";

interface Props {
    isOpen: boolean
    onHide: () => void
    year: number
}

const BookGoalAddModal: React.FC<Props> = ({isOpen, onHide, year}) => {
    const {addGoal} = useBookGoalStore()
    const [goalCount, setGoalCount] = useState<number>(0)

    const MIN_GOAL_COUNT = 3
    const MAX_GOAL_COUNT = 200

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (goalCount < MIN_GOAL_COUNT) {
            toast.error(`${MIN_GOAL_COUNT}권 이상의 목표 권수를 입력해 주세요.`)
            return
        }

        if (goalCount > MAX_GOAL_COUNT) {
            toast.error(`목표는 ${MAX_GOAL_COUNT}권까지만 설정할 수 있어요.`)
            return
        }

        BooksitoutServer
            .post(`/v1/book/goals/${year}`, {goal: goalCount})
            .then((res) => {
                addGoal(res.data)
                toast.success('목표가 추가되었습니다.')
                onHide()
            })
            .catch(() => toast.error('오류가 발생했어요. 잠시 후 다시 시도해 주세요.'))
    }

    return (
        <Modal
            isShowing={isOpen}
            onClose={onHide}
            titleText={`🗓️ ${year}년도 목표 추가하기`}
            body={
                <Form onSubmit={onSubmit}>
                    <Form.Group>
                        <NumberInput
                            placeholder="목표 책 권수"
                            onChange={(e) => setGoalCount(Number(e.target.value))}
                            autoFocus={true}
                            min={1}
                            max={MAX_GOAL_COUNT}
                        />

                        <RowSpacer size={10}/>

                        {/*@ts-ignore*/}
                        <AddButton label='추가하기' onClick={onSubmit}/>
                    </Form.Group>
                </Form>
            }
        />
    )
}

export default BookGoalAddModal