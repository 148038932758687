import React from 'react'
import {Card} from "react-bootstrap";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import styled from "styled-components";
import LoadingBar from "../../../common/LoadingBar";
import BooksitoutImages from "../../../images/BooksitoutImages";

interface Props {
    height?: number
}

const SearchBookCardLoading: React.FC<Props> = ({height}) => {
    return (
        <Card>
            <CardBodyContentContainer height={150}>
                <Container>
                    <ContentContainer>
                        <Cover src={BooksitoutImages.Placeholder.bookCoverLoading} alt={'placeholder'} height={height ?? 200}/>
                        <RowSpacer size={12.5}/>

                        <Title><LoadingBar size={5} /></Title>
                        <RowSpacer size={2.5}/>

                        <Authors><LoadingBar size={8} /></Authors>
                    </ContentContainer>
                </Container>
            </CardBodyContentContainer>
        </Card>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    padding-left: 20px;
    padding-right: 20px;
`;


const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
`;

const Cover = styled.img.attrs({
    className: 'img-fluid'
})`
    max-height: 150px;
    min-height: 150px;
    width: auto;
    max-width: 100%;
    object-fit: contain;
`;

const Title = styled.h1.attrs({
    className: 'clamp-1-line'
})`
    font-size: 15px;
    text-align: center;
`;

const Authors = styled.h2.attrs({
    className: 'text-secondary clamp-1-line'
})`
    font-size: 13px;
`;

export default SearchBookCardLoading
