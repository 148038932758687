import React, {useState} from 'react'
import styled from 'styled-components';
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import Spacer from "../../../common/Spacer";
import {Card, Table as BootstrapTable} from "react-bootstrap";
import BookIsbnResponse, {BookLinkType} from "./BookIsbnResponse";
import BooksitoutImages from "../../../images/BooksitoutImages";
import AddBookSearchModal from "../add/AddBookSearchModal";
import BookAddBadge from "../BookAddBadge";
import {useNavigate} from "react-router-dom";

interface Props {
    book: BookIsbnResponse
}

const BookIsbnCard: React.FC<Props> = ({book}) => {
    const navigate = useNavigate()

    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
    const addModal = () => {
        setIsAddModalOpen(true)
    }

    return (
        <Card onClick={() => book.bookId && navigate(`/book/mine/${book.bookId}`)}
              className={`${book.bookId && 'clickable'}`}>
            <AddBookSearchModal
                isAddModalOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                selectedBook={{
                    isbn13: book.isbn,
                    title: book.title,
                    subTitle: book.subTitle,
                    authors: book.author,
                    cover: book.cover,
                    page: book.page
                }}
            />

            <CardBodyContentContainer height={400}>
                <AddButtonContainer>
                    <BookAddBadge book={book} onClick={addModal}/>
                </AddButtonContainer>

                <Container>
                    <BookCoverContainer>
                        <BookCover src={book.cover} alt={book.title}/>
                    </BookCoverContainer>

                    <BookInfoContainer>
                        <div>
                            <BookTitle>{book.title}</BookTitle>
                            <BookAuthor>{book.author}</BookAuthor>
                        </div>

                        <Spacer/>

                        <Table hover>
                            <tbody>
                            <tr>
                                <td><InfoTitle>출판사</InfoTitle></td>
                                <td><InfoContent>{book.publisher}</InfoContent></td>
                            </tr>

                            <tr>
                                <td><InfoTitle>페이지</InfoTitle></td>
                                <td><InfoContent>{book.page?.toString() ?? "?"}쪽</InfoContent></td>
                            </tr>

                            <tr>
                                <td><InfoTitle>출판년도</InfoTitle></td>
                                <td><InfoContent>{book.publicationYear}년</InfoContent></td>
                            </tr>
                            </tbody>
                        </Table>

                        <Spacer/>

                        <div>
                            <BookLink type={'NAVER'} url={book.externalLinks.naver}/>
                            <BookLink type={'ALADIN'} url={book.externalLinks.aladin}/>
                            <BookLink type={'YES24'} url={book.externalLinks.yes24}/>
                            <BookLink type={'KYOBO'} url={book.externalLinks.kyobo}/>
                        </div>
                    </BookInfoContainer>
                </Container>
            </CardBodyContentContainer>
        </Card>
    )
}

const Container = styled.div.attrs({
    className: 'row'
})`
    height: 100%;
    padding: 20px;
`

const BookCoverContainer = styled.div.attrs({
    className: 'col-12 col-sm-6 col-md-4 col-xl-3'
})`
    height: 100%;
    align-content: center;
`

const BookInfoContainer = styled.div.attrs({
    className: 'col-12 col-sm-6 col-md-8 col-xl-9'
})`
    padding-left: 30px;
    padding-top: 30px;

    display: flex;
    flex-direction: column;
`

const BookCover = styled.img.attrs({
    className: 'img-fluid rounded border'
})``

const BookTitle = styled.h1`
    font-size: 2rem;
    font-weight: bold;
`

const BookAuthor = styled.h2.attrs({
    className: 'text-secondary'
})`
    font-size: 1.5rem;
    font-weight: bold;
`

const InfoTitle = styled.td``

const InfoContent = styled.td.attrs({
    className: 'text-secondary'
})``

const Table = styled(BootstrapTable)`
    max-width: 500px;
`

const AddButtonContainer = styled.div`
    position: absolute;
    right: 20px;
`

interface BookLinkProps {
    type: BookLinkType,
    url: string | null
}

const BookLink: React.FC<BookLinkProps> = ({ type, url }) => {
    const navigate = useNavigate();

    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation()

        if (!url) {
            e.preventDefault()
            return
        }

        if (e.currentTarget.target !== '_blank') {
            e.preventDefault()
            navigate(url)
        }
    }

    return (
        <LinkATag
            href={url || undefined}
            target={url ? '_blank' : undefined}
            onClick={onClick}
        >
            {type === 'NAVER' ? (
                <LinkImage src={BooksitoutImages.WebsiteLogo.naver} alt="Naver" />
            ) : type === 'ALADIN' ? (
                <LinkImage src={BooksitoutImages.WebsiteLogo.aladin} alt="Aladin" />
            ) : type === 'YES24' ? (
                <LinkImage src={BooksitoutImages.WebsiteLogo.yes24} alt="Yes24" />
            ) : type === 'KYOBO' ? (
                <LinkImage src={BooksitoutImages.WebsiteLogo.kyobo} alt="Kyobo" />
            ) : null}
        </LinkATag>
    )
};

interface LinkATagProps {
    href?: string;
}

const LinkATag = styled.a.attrs<LinkATagProps>((props) => ({
    className: 'border clickable',
    href: props.href || undefined,
}))`
    ${(props) =>
            !props.href &&
            `
        pointer-events: none;
        color: grey;
        text-decoration: none;
        opacity: 0.5;
    `}
    
    margin-left: 5px;
    margin-right: 5px;
`;

const LinkImage = styled.img.attrs({
    className: 'rounded hover-effect clickable',
})`
    width: 50px;
    height: 50px;
`

export default BookIsbnCard
