import React from "react";
import styled from 'styled-components';
import { VscError as XMarkIcon } from 'react-icons/vsc'
import breakpoints from "../config/Breakpoints";
import parse from 'html-react-parser'

interface Props {
	message: string
	adjustForSmallScreen?: boolean
	size?: '1em' | '2em' | '3em' | '4em' | '5em' | '6em'
}

const NoContent: React.FC<Props> = ({ message = '찾으시는 내용이 없어요', adjustForSmallScreen, size }) => {
	return (
		<Container adjustForSmallScreen={adjustForSmallScreen ?? false}>
			<ContentContainer>
				<IconContainer>
					<XMarkIcon className="text-book" size={size ?? '5em'} />
				</IconContainer>
				<Text>{parse(message)}</Text>
			</ContentContainer>
		</Container>
	)
}

interface ContainerProps {
	adjustForSmallScreen: boolean
}

const Container = styled.div<ContainerProps>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	
	@media (max-width: ${breakpoints.md}) {
		top: ${props => props.adjustForSmallScreen ? '70%' : '50%'};
	}
`;

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
`;

const IconContainer = styled.div`
	text-align: center;
`;

const Text = styled.h5.attrs({
	className: 'force-1-line'
})`
	margin-top: 0.5em;
	text-align: center;
`;

export default NoContent
