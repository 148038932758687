import aladin from './logo/aladin.png'
import interpark from './logo/interpark.png'
import kyobo from './logo/kyobo.png'
import millie from './logo/millie.png'
import ridi from './logo/ridi.png'
import yes24 from './logo/yes24.png'
import appStore from './logo/apple-appstore.png'

import naver from './logo/naver.png'

import gwangwamunLibrary from './logo/gwanghwamun-library.jpg'
import gyeonggiEducationLibrary from './logo/gyeonggi-education-library.png'
import gyeonggiLibrary from './logo/gyeonggi-library.png'
import nationalAssemblyLibrary from './logo/national-assembly-library.png'
import seoulCongressLibrary from './logo/seoul-congress-library.png'
import seoulEducationLibrary from './logo/seoul-education-library.png'
import seoulLibrary from './logo/seoul-library.png'

import booksitout from './logo/booksitout.png'
import purrfitTracker from './logo/purrfitTracker.png'
import substrack from './logo/substrack.png'
import featuredDays from './logo/featureddaysicon.png'
import pianoMan from './logo/pianoman.png'

import bookCover from './placeholder/default-book-cover.png'
import bookCoverLoading from './placeholder/default-book-cover-loading.png'
import libraryLoading from './placeholder/square-loading.png'
import user from './placeholder/user.png'

import relax from './illustration/relax.svg'

import appleLogin from './login-button/small-apple.png'
import facebookLogin from './login-button/small-facebook.png'
import googleLogin from './login-button/small-google.png'
import kakaoLogin from './login-button/small-kakao.png'
import naverLogin from './login-button/small-naver.png'
import samsungLogin from './login-button/small-samsung.png'
import twitterLogin from './login-button/small-twitter.png'

const BooksitoutImages = {
    WebsiteLogo: {
        aladin: aladin,
        interpark: interpark,
        kyobo: kyobo,
        millie: millie,
        ridi: ridi,
        yes24: yes24,
        appStore: appStore,

        naver: naver,

        booksitout: booksitout,
        purrfitTracker: purrfitTracker,
        substrack: substrack,
        featuredDays: featuredDays,
        pianoman: pianoMan,
    },

    LibraryLogo: {
        광화문도서관: gwangwamunLibrary,
        경기도서관: gyeonggiLibrary,
        경기교육도서관: gyeonggiEducationLibrary,
        국회도서관: nationalAssemblyLibrary,
        서울의회도서관: seoulCongressLibrary,
        서울도서관: seoulLibrary,
        서울교육도서관: seoulEducationLibrary,
    },

    Placeholder: {
        user: user,
        bookCover: bookCover,
        bookCoverLoading: bookCoverLoading,
        libraryLoading: libraryLoading,
    },

    Illustration: {
        relax: relax,
    },

    LoginButton: {
        google: googleLogin,
        kakao: kakaoLogin,
        naver: naverLogin,

        apple: appleLogin,
        facebook: facebookLogin,
        samsung: samsungLogin,
        twitter: twitterLogin,
    }
}

export default BooksitoutImages