import {useEffect, useState} from "react"
import {SearchBookSourceLibraryOnlineResponse} from "../SearchBookSourceLibraryResponse"
import {BooksitoutServer} from "../../../../config/BooksitoutServer"
import {BookSourceLibraryOnlineValues} from "../BookSource"

const useBookLibraryOnline = (isbn13: string) => {
    const [books, setBooks] = useState<SearchBookSourceLibraryOnlineResponse[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (isbn13 !== '') {
            BooksitoutServer
                .get(`/v1/book/search/sources/library/online?isbn13=${isbn13}&sources=${Array.from(BookSourceLibraryOnlineValues).join(",")}`)
                .then((res) => setBooks(res.data))
                .finally(() => setIsLoading(false))
        }
    }, [isbn13])

    return [books, isLoading] as const
}

export default useBookLibraryOnline
