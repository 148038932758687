import RouteContainer from '../../common/styles/RouteContainer';
import IndexSearchBar from './card/IndexSearchBar';
import IndexProfilesCard from './card/IndexProfilesCard';
import IndexTipsCard from './card/IndexTipsCard';
import IndexLibraryCard from './card/IndexLibraryCard';
import IndexSummaryCard from './card/IndexSummaryCard';
import RowSpacer from '../../common/styles/RowSpacer';
import IndexPopularBookCard from './card/IndexPopularBookCard';
import IndexAfterReleaseCard from './card/IndexAfterReleaseCard';

const IndexRoute = () => {
    return (
        <RouteContainer>
            <RowSpacer size={10}/>

            <IndexAfterReleaseCard/>
            <RowSpacer size={20}/>

            <IndexSearchBar/>
            <RowSpacer size={60}/>

            <IndexSummaryCard/>
            <RowSpacer size={20}/>

            <IndexPopularBookCard/>
            <RowSpacer size={20}/>

            <IndexProfilesCard/>
            <RowSpacer size={20}/>

            <IndexTipsCard/>
            <RowSpacer size={20}/>

            <IndexLibraryCard/>
            <RowSpacer size={20}/>
        </RouteContainer>
    )
}

export default IndexRoute