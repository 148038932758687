import styled from 'styled-components';
import BookGoalCard from "../goal/BookGoalCard"
import ContentContainer from '../../../common/styles/ContentContainer';
import useBookGoalsAll from "../goal/useBookGoals";
import useBookGoalStore from "../goal/useBookGoalStore";
import {useEffect} from "react";

const BookMineGoalCard = () => {
    const {init, getGoal, deleteGoal} = useBookGoalStore()

    const [goalsFromServer, isLoading] = useBookGoalsAll()
    useEffect(() => {
        init(goalsFromServer)
    }, [goalsFromServer]);

    return (
        <Container>
            <ContentContainer href='/book/goal'>
                <BookGoalCard size={40} isLoading={isLoading}/>
            </ContentContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: end;
    
    max-height: 200px;
    width: 100%;
`;

export default BookMineGoalCard