import React, {useEffect, useState} from 'react'
import {styled} from "styled-components";
import RouteContainer from "../../common/styles/RouteContainer"
import RowSpacer from "../../common/styles/RowSpacer"
import {Button as BootstrapButton, Card} from "react-bootstrap"
import CardBodyContentContainer from "../../common/styles/CardBodyContentContainer";
import Spacer from "../../common/Spacer";
import {BooksitoutCardTitle} from "../../booksitout/BooksitoutCard";
import CardTitle from "../../common/styles/CardTitle";
import BooksitoutLogo from "../../common/BooksitoutLogo";
import BooksitoutIcon from '../../config/BooksitoutIcon';

interface BeforeInstallPromptEvent extends Event {
    prompt: () => Promise<void>
    userChoice: Promise<{ outcome: 'accepted' | 'dismissed'; platform: string }>
}

const PwaRoute: React.FC = () => {
    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null)

    useEffect(() => {
        const handleBeforeInstallPrompt = (e: Event) => {
            e.preventDefault()
            setDeferredPrompt(e as BeforeInstallPromptEvent)
        }

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
        return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    }, [])

    const handleInstallClick = async () => {
        if (deferredPrompt !== null) {
            await deferredPrompt.prompt()
            const {outcome} = await deferredPrompt.userChoice
            if (outcome === 'accepted') {
                setDeferredPrompt(null)
            }
        }
    }

    return (
        <RouteContainer>
            <RowSpacer/>
            <Card>
                <CardBodyContentContainer height={300}>
                    <CardTitle
                        icon={<BooksitoutIcon.pwa/>}
                        title={`책잇아웃 앱으로 쓰기`}
                        url={null}
                    />

                    <RowSpacer size={30}/>

                    <Row>
                        <Col>
                            {/*@ts-ignore*/}
                            <Button variant='book' onClick={handleInstallClick} disabled={!deferredPrompt}>
                                설치하기
                            </Button>
                        </Col>
                    </Row>
                </CardBodyContentContainer>
            </Card>

            <RowSpacer/>
            <Card>
                <CardBodyContentContainer height={600}>
                    <CardTitle
                        icon={<BooksitoutIcon.notSupported/>}
                        title={`아이폰, 아이패드나 사파리라면?`}
                        url={null}
                    />

                    <RowSpacer size={30}/>
                    <Row>
                        <img src="https://booksitout-bucket.s3.ap-northeast-2.amazonaws.com/tips/pwa-ios.png"
                             className="img-fluid w-100 rounded"/>
                    </Row>
                </CardBodyContentContainer>
            </Card>

            <RowSpacer/>
        </RouteContainer>
    )
}

const Row = styled.div.attrs({
    className: 'row'
})`
    justify-content: center;
`

const Col = styled.div.attrs({
    className: 'col-12 col-md-6'
})``

const Button = styled(BootstrapButton).attrs({
    variant: 'book'
})`
    width: 100%;
`

export default PwaRoute