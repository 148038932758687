import React from "react";
import styled from 'styled-components';
import SearchBookResponse from './SearchBookResponse'
import {Card} from 'react-bootstrap'
import RowSpacer from '../../../common/styles/RowSpacer';
import {useNavigate} from 'react-router-dom';
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import BooksitoutIcon from "../../../config/BooksitoutIcon";
import ColorConfig from "../../../config/ColorConfig";

interface Props {
    book: SearchBookResponse
    onClick?: () => void
    url?: string
    height?: number
}

const SearchBookCard: React.FC<Props> = ({book, onClick, url, height}) => {
    const navigate = useNavigate()

    const defaultOnClick = () => {
        navigate(`/search/${book.isbn13}`)
    }

    if (url != null) {
        return (
            <a href={url}>
                <Container>
                    <Card className='clickable' onClick={onClick}>
                        <CardBodyContentContainer height={150}>
                            {
                                book.isAdded &&
                                (
                                    <AddButtonContainer>
                                        <CheckMark/>
                                    </AddButtonContainer>
                                )
                            }

                            <ContentContainer>
                                <Cover src={book.cover} alt={book.title} height={height ?? 200}/>
                                <RowSpacer size={12.5}/>

                                <Title>{book.title}</Title>
                                <RowSpacer size={2.5}/>

                                <Authors>{book.authors == "" ? '?' : book.authors}</Authors>
                            </ContentContainer>
                        </CardBodyContentContainer>
                    </Card>
                </Container>
            </a>
        )
    }


    return (
        <Card className='clickable' onClick={onClick || defaultOnClick}>
            {
                book.isAdded &&
                (
                    <AddButtonContainer>
                        <CheckMark/>
                    </AddButtonContainer>
                )
            }

            <CardBodyContentContainer height={150} style={{opacity: book.isAdded ? '60%' : '100%'}}>
                <ContentContainer>
                    <Cover src={book.cover} alt={book.title} height={height ?? 200}/>
                    <RowSpacer size={10}/>

                    <Title>{book.title}</Title>
                    <Authors>{book.authors == "" ? '?' : book.authors}</Authors>
                </ContentContainer>
            </CardBodyContentContainer>
        </Card>
    );
}

const Container = styled.a`
    padding-left: 2.5px;
    padding-right: 2.5px;

    height: 350px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
`;

const Cover = styled.img.attrs({
    className: 'img-fluid'
})`
    max-height: 150px;
    min-height: 150px;
    width: auto;
    max-width: 100%;
    object-fit: contain;
`;

const Title = styled.h1.attrs({
    className: 'clamp-1-line'
})`
    font-size: 15px;
    text-align: center;
`;

const Authors = styled.h2.attrs({
    className: 'text-secondary clamp-1-line'
})`
    font-size: 13px;
`;

const AddButtonContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 5px;
`

const CheckMark = styled(BooksitoutIcon.check)`
    font-size: 25px;
    color: ${ColorConfig.Primary};
`

export default SearchBookCard
