import { useState, useEffect } from 'react'

type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
}

const getScreenSize = (width: number): ScreenSize => {
    if (width < breakpoints.sm) return 'xs'
    if (width < breakpoints.md) return 'sm'
    if (width < breakpoints.lg) return 'md'
    if (width < breakpoints.xl) return 'lg'
    return 'xl'
}

const useScreenSize = (): ScreenSize => {
    const [screenSize, setScreenSize] = useState<ScreenSize>(
        getScreenSize(window.innerWidth)
    )

    useEffect(() => {
        const handleResize = () => setScreenSize(getScreenSize(window.innerWidth))
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return screenSize
}

export default useScreenSize