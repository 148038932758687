import React from 'react'
import styled from 'styled-components';
import useBookDetailStore from "../../../book/detail/useBookDetailStore";
import IndexContentContainer from "../../../index/IndexContentContainer";
import ColorConfig from "../../../../config/ColorConfig";

const ProfileBookDetailMemoCard = () => {
    const {memos} = useBookDetailStore()

    const removeHTMLTags = (input: string) => {
        return input
            .replaceAll(/<[^>]*>/g, '')
            .replaceAll(/&[a-zA-Z]+;/g, '')
    }

    return (
        <IndexContentContainer>
            <Container>
                {memos.length === 0 ?
                    <EmptyCaseContainer>
                        <EmptyCaseText>✏️ 추가된 메모가 없어요!</EmptyCaseText>
                    </EmptyCaseContainer>
                    :
                    memos.map(memo => (
                        <Memo key={memo.id}>
                            <PageNumber>{memo.page ?? '-'}</PageNumber>
                            <MemoContent>{removeHTMLTags(memo.content)}</MemoContent>
                        </Memo>
                    ))
                }
            </Container>
        </IndexContentContainer>
    )
}

const EmptyCaseContainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: black;
`;

const EmptyCaseText = styled.h3`
    text-align: center;
`;

const Container = styled.div.attrs({
    className: 'row row-eq-height'
})`
    min-height: 250px;
    width: 100%;
`;

const Memo = styled.div.attrs({
    className: 'col-12 col-md-6 col-xl-4'
})`
    background-color: #fff9c4;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;

    height: 100px;
`;

const PageNumber = styled.div`
    font-size: 0.85em;
    font-weight: bold;
    margin-bottom: 5px;
    color: ${ColorConfig.Primary};
`;

const MemoContent = styled.div.attrs({
    className: 'clamp-2-line'
})`
    font-size: 1em;
    line-height: 1.5;
    color: #333;
`;

export default ProfileBookDetailMemoCard
