import {useParams} from "react-router-dom"
import RouteContainer from "../../../common/styles/RouteContainer"
import useBookDetail from "./useBookDetail"
import NoContent from "../../../common/NoContent"
import RowSpacer from '../../../common/styles/RowSpacer';
import BookDetailInfoCard from './BookDetailInfoCard';
import BookDetailMemoCard from './memo/BookDetailMemoCard';
import BookDetailReadingSessionCard from './reading/BookDetailReadingSessionCard';
import RouteTitle from '../../../common/RouteTitle/RouteTitle';
import booksitoutIcon from '../../../config/BooksitoutIcon';
import RouteTitleConfig from '../../../config/RouteTitleConfig';
import {useEffect} from "react";
import useBookDetailStore from "./useBookDetailStore";
import useLoginStore from "../../login/useLoginStore";
import useHtmlTitle from "../../../common/useHtmlTitle";

const BookDetailRoute = () => {
    const {bookId} = useParams()
    const [bookDetail, isLoading, isError] = useBookDetail(Number(bookId))
    useHtmlTitle(bookDetail?.book.title)

    const isLoggedIn = useLoginStore((state) => state.isLoggedIn())
    useEffect(() => {
        let userId =  bookDetail?.book?.userId
        if (!isLoggedIn && userId != undefined) {
            window.location.href = `/users/${userId}/books/${bookId}`
        }
    }, [isLoggedIn, bookDetail]);

    const {init} = useBookDetailStore()
    useEffect(() => {
        if (bookDetail != null) {
            init(bookDetail)
        }
    }, [bookDetail])

    if (isLoading) {
        return <></>
    }

    if (isError || bookDetail == null) {
        return <NoContent message={"찾으시는 책이 없어요."}/>
    }

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.book/>}
                title={'내 서재'}
                subTitle={'내가 등록한 책을 확인하고 관리할 수 있어요'}
                currentKey={'mine'}
                buttons={RouteTitleConfig.Book}
                rightUi={undefined}
            />

            <RowSpacer/>
            <BookDetailInfoCard/>

            <RowSpacer size={10}/>
            <BookDetailMemoCard/>

            <RowSpacer size={10}/>
            <BookDetailReadingSessionCard/>
        </RouteContainer>
    )
}

export default BookDetailRoute
