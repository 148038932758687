import {Card} from 'react-bootstrap'
import CardTitle from '../../../../common/styles/CardTitle'
import DateLineChart from '../DateLineChart'
import DateLineChartLoading from '../DateLineChartLoading'
import useBookStatisticsReadTime from '../hooks/useBookStatisticsReadTime'
import useScreenSize from "../../../../common/hooks/useScreenSize";

const BookStatisticsReadTimeCard = () => {
    const [readTimeList, isLoading] = useBookStatisticsReadTime(30)

    const size = useScreenSize()
    const smallSize = 14

    return (
        <Card style={{height: '382.5px'}}>
            <Card.Body>
                <CardTitle icon={<></>} title={`최근 ${(size == 'sm' || size == 'xs' || size == 'md') ? 7 : 30}일 독서시간`} url={''}/>

                {
                    (size == 'sm' || size == 'xs' || size == 'md') ?
                        (
                            isLoading ?
                                <DateLineChartLoading
                                    startDate={new Date().setDate(new Date().getDate() - smallSize)}
                                    duration={smallSize}
                                />
                                :
                                <DateLineChart
                                    startDate={new Date().setDate(new Date().getDate() - smallSize)}
                                    data={readTimeList.slice(30 - smallSize)}
                                />
                        )
                        :
                        (
                            isLoading ?
                                <DateLineChartLoading
                                    startDate={new Date().setDate(new Date().getDate() - 30)}
                                    duration={30}
                                />
                                :
                                <DateLineChart
                                    startDate={new Date().setDate(new Date().getDate() - 30)}
                                    data={readTimeList}
                                />
                        )
                }
            </Card.Body>
        </Card>
    )
}

export default BookStatisticsReadTimeCard