const FaqData = [
    {
        id: 1,
        question: `왜 아이디/비밀번호로 로그인는 할 수 없나요?`,
        answer: `
            2가지 이유가 있어요.
            <br/>
            <br/>
            1. 아이디/비밀번호 로그인은 생각보다 기능이 엄청 많아요.
            <ul>
                <li>비밀번호는 암호화를 비롯해 철저히 관리해야 해야 하고,</li>            
                <li>이메일을 아이디로 쓴다면 이메일 인증을 해야 하고,</li>            
                <li>아이디/비밀번호를 잊을 때를 대비해 아이디/비밀번호 찾기를 만들어야 해요.</li>            
                <li>그래서 과감하게 포기하고 더 중요한 기능에 집중하기로 했어요.</li>            
            </ul>
            
            <br/>
            
            2. 카카오/네이버/애플/구글로 로그인하면 책잇아웃만을 위한 아이디/비밀번호를 기억하지 않아도 돼요.
            <ul>
                <li>여러 사이트의 아이디/비밀번호를 기억하는건 어려워요.</li>            
                <li>카카오/네이버/애플/구글 로그인을 사용하면 책잇아웃만의 아이디/비밀번호를 관리할 필요가 없어서 더 편리해요.</li>            
            </ul>
            <br/>
        `,
    },
    {
        id: 2,
        question: '내 카카오/네이버/애플/구글 계정의 개인정보가 책잇아웃에 제공되면 어쩌죠?',
        answer: `
            <ul>
               <li>카카오/네이버/애플/구글은 정책에 따라 개인정보를 직접 제공하지 않고, 암호화 된 토큰으로 간접적으로 제공해요.</li>
               <li>따라서 책잇아웃은 동의하신 정보 이외에 어떤 정보도 알 수 없어요.</li>
               <li>
                    게다가 책잇아웃에서는 이메일을 제외하고 어떤 정보도 필수로 요구하지 않아요. 
                    원치 않은 경우 이름, 프로필 사진은 제공하지 않으셔도 돼요. 
                    이름, 프로필 사진은 단순히 화면에서 보여주기 용도로만 사용 되고 핵심 기능은 해당 정보를 제공하지 않으셔도 사용에 지장 없어요.
                </li>
                <li>추가로 이메일, 이름, 프로필 사진은 암호화 돼 저장해 본인을 제외하고는 볼 수 없어요. 자세한건 <a href="https://booksitout.com/privacy/2023-07-01?language=KOREAN" class="text-book fw-bold">개인정보처리방침</a>을 참고해 주세요.</li>
            </ul>
        `
    },
    {
        id: 3,
        question: '내 카카오/네이버/애플/구글 계정으로 원치 않는 이메일이나 알림이 오면 어쪄죠?',
        answer: `
            <ul>
                <li>책잇아웃팀의 구성원 또한 언제 동의한지도 모르는 마케팅 알림을 받는걸 매우 싫어해요.</li>
                <li>책잇아웃에서는 법적으로 꼭 보내야 하는 내용을 제외하고 어떤 이메일이나 알림도 보내지 않으니 안심하세요.</li>
                <li>추가로, 2024년 11월 기준 알림 기능 자체가 없답니다. (추후 원하는 책이 원하는 곳에서 제공됐을 때 알림을 주는 서비스 추가 예정)</li>
            </ul>
        `
    }
]


export default FaqData