import BooksitoutDemoImage from "../demos/BooksitoutDemoImage";
import BooksitoutProductType from "../../../booksitout/type/BooksitoutProductType";
import BooksitoutProductStatus from "../../../booksitout/type/BooksitoutProductStatus";
import BooksitoutImages from "../../../images/BooksitoutImages";

const booksitoutProductPurrfitTracker: BooksitoutProductType = {
    id: 'purrfitTracker',
    status: BooksitoutProductStatus.PRODUCTION,
    name: {
        english: 'Purrfit Tracker',
        url: 'purrfit-tracker',
    },
    icon: BooksitoutImages.WebsiteLogo.purrfitTracker,
    link: [
        {
            link: 'https://apps.apple.com/app/purrfit-tracker/id6458539987',
            icon: BooksitoutImages.WebsiteLogo.appStore,
            border: false,
        },
    ],
    thumbnailImage: BooksitoutDemoImage.purrfitTracker,
    detailImage: BooksitoutDemoImage.purrfitTracker,
    demoImages: [BooksitoutDemoImage.purrfitTracker],
    featuresCount: 3,
    privacyPolicyDates: ['2023-10-30'],
}

export default booksitoutProductPurrfitTracker